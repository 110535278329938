/* eslint-disable max-len */
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AdditionalInformation } from '@1po/1po-bff-fe-spec/generated/catalog/references/common/model/AdditionalInformation';
import { ReferenceBrandType } from '@1po/1po-bff-fe-spec/generated/catalog/references/common/model/ReferenceBrandType';
import { LinkedReference } from '@1po/1po-bff-fe-spec/generated/catalog/references/dh/model/Reference';
import { VehicleBrandType } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { ROUTER_CART, ROUTER_ESTIMATE } from 'app/AppRouter';
import { trackAppEvent } from 'app/AppTracker';
import { useFetchSingleReference } from 'domains/catalog/Catalog.requests';
import {
  addReferenceFromCatalogToCart,
  getLastSearchedVehicleKey,
  getLastVehicleDetail,
} from 'domains/catalog/Catalog.store';
import { convertReferenceToEstimateReference } from 'domains/estimate/Estimate.mapper';
import { addCatalogReference, setSelectedTab } from 'domains/estimate/Estimate.store';
import { EstimateTabName } from 'domains/estimate/Estimate.types';
import { ADDITIONAL_INFO_DISPLAY_STYLE, ReferencePriceType } from 'domains/references';
import { CompactView, getCatalogReferenceView, getDealerType, SparePartsViewType } from 'domains/user';
import ReferenceCard from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard/index';
import ReferenceCardCompact from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard/ReferenceCardCompact';
import { MarginBox, MinusCircleButton, NotificationLink, notifyTop, PlusCircleButton, Text } from 'UI';
import {
  TRACKING_EVENT_ADD_TO_CART,
  TRACKING_EVENT_ADD_TO_ESTIMATE,
  TRACKING_EVENT_GO_TO_CART_SHORTCUT,
  TRACKING_EVENT_GO_TO_ESTIMATE,
} from 'utils/eventTracker/EventTracker.types';

interface ReferenceCardWrapperProps {
  name?: string;
  referenceNumber: string;
  referenceBrand?: ReferenceBrandType;
  vehicleBrand: VehicleBrandType;
  price?: ReferencePriceType;
  sparePartsView: SparePartsViewType;
  displayPlateLink?: boolean;
  additionalInfo?: AdditionalInformation[];
  additionalInfoDisplayStyle?: ADDITIONAL_INFO_DISPLAY_STYLE;
  displayAdditionalInfo?: boolean;
  plateId?: string;
  linkedReferences: LinkedReference[];
  isApplicableToCurrentVehicle: boolean | undefined;
  seePlateState?: [string | undefined, (x: string | undefined) => void];
  firstRefWithPrice?: string;
  firstRefWithLaborTimes?: string;
  firstRefWithPriceNotInCart?: string;
  refIndex?: number;
  displaySupersessionTooltip?: boolean;
  useCompactView?: boolean;
  genericPart?: string;
  index?: number;
  familyCode?: string;
  imageKeys?: string[];
}

const ReferenceCardWrapper = ({
  referenceNumber,
  name,
  referenceBrand,
  vehicleBrand,
  price,
  sparePartsView,
  displayPlateLink = false,
  additionalInfo,
  displayAdditionalInfo,
  additionalInfoDisplayStyle,
  linkedReferences,
  plateId,
  isApplicableToCurrentVehicle,
  seePlateState,
  firstRefWithPrice,
  firstRefWithLaborTimes,
  firstRefWithPriceNotInCart,
  refIndex,
  displaySupersessionTooltip,
  useCompactView,
  genericPart,
  index,
  familyCode,
  imageKeys,
}: ReferenceCardWrapperProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const catalogRefView = useSelector(getCatalogReferenceView);
  const vehicleDetail = useSelector(getLastVehicleDetail);
  const [linkedReferencesActive, setLinkedReferencesActive] = useState(false);
  const isR1 = useSelector(getDealerType) === 'R1';
  const availableForOrder = vehicleBrand === 'ALPINE' ? isR1 : true;
  const linkedReferenceNumbers = linkedReferences?.map((ref) => ref.referenceNumber) ?? [];

  useFetchSingleReference(referenceNumber, null, vehicleKey);

  const handleAddToCartClick = () => {
    notifyTop(
      'success',
      <>
        {linkedReferenceNumbers && linkedReferenceNumbers.length > 0 ? (
          <Trans i18nKey={'catalog.reference_card.added_to_basket_with_linked.description'}>
            {'Reference has been added to your cart with linked references.'}
          </Trans>
        ) : (
          <Trans i18nKey={'catalog.reference_card.added_to_basket.description'}>
            {'Reference has been added to your cart'}
          </Trans>
        )}
      </>,
      undefined,
      <NotificationLink
        onClick={() => {
          trackAppEvent(TRACKING_EVENT_GO_TO_CART_SHORTCUT);
          history.push(ROUTER_CART);
        }}
      >
        <Trans i18nKey={'catalog.reference_card.added_to_basket.go_to_cart'}>{'Go to cart'}</Trans>
      </NotificationLink>,
      'notification-added-to-cart',
    );
    setLinkedReferencesActive(true);
    trackAppEvent(TRACKING_EVENT_ADD_TO_CART);
    dispatch(addReferenceFromCatalogToCart({ referenceNumber, plateId, index }));
  };

  const renderLinkedReferencesSwitch = () => {
    return (
      <>
        {linkedReferencesActive ? (
          <MarginBox mx={-12}>
            <MinusCircleButton size={16} onClick={() => setLinkedReferencesActive(!linkedReferencesActive)} />
          </MarginBox>
        ) : (
          <MarginBox mx={-12}>
            <PlusCircleButton size={16} onClick={() => setLinkedReferencesActive(!linkedReferencesActive)} />
          </MarginBox>
        )}
        {catalogRefView === CompactView && <MarginBox ml={3} />}
        <Text
          onClick={() => setLinkedReferencesActive(!linkedReferencesActive)}
          type={'light_12'}
          displayStyle={'link'}
          cursor={'pointer'}
        >
          <Trans count={linkedReferenceNumbers.length} i18nKey={'catalog.reference_card.action.linked_references'}>
            {'{{count}} Linked References'}
          </Trans>
        </Text>
      </>
    );
  };

  const handleAddToEstimateClick = () => {
    if (vehicleDetail && price?.garageView?.vatExcludedPrice && vehicleDetail.dataHubVehicle) {
      notifyTop(
        'success',
        linkedReferences.length > 0 ? (
          <>
            <Trans i18nKey={'catalog.reference_card.added_with_linked_references_to_estimate.description'}>
              {'Reference has been added to your estimate with linked references'}
            </Trans>
          </>
        ) : (
          <Trans i18nKey={'catalog.reference_card.added_to_estimate.description'}>
            {'Reference has been added to your estimate.'}
          </Trans>
        ),
        undefined,
        <NotificationLink
          onClick={() => {
            trackAppEvent(TRACKING_EVENT_GO_TO_ESTIMATE);
            dispatch(setSelectedTab(EstimateTabName));
            history.push(`${ROUTER_ESTIMATE}`);
          }}
        >
          <Trans i18nKey={'catalog.parts.category.car_parts.labor_time.notification.see_estimate'}>
            {'See estimate'}
          </Trans>
        </NotificationLink>,
      );
      trackAppEvent(TRACKING_EVENT_ADD_TO_ESTIMATE);
      dispatch(
        addCatalogReference({
          reference: convertReferenceToEstimateReference(
            referenceNumber,
            name ?? '',
            price,
            isApplicableToCurrentVehicle ?? true,
            'DATAHUB',
            referenceBrand,
            undefined,
            undefined,
            undefined,
            undefined,
            linkedReferences,
            familyCode,
            imageKeys && imageKeys.length > 0 ? imageKeys[0] : undefined,
          ),
        }),
      );
    }
  };

  const renderReferenceCard = () => {
    if (useCompactView && catalogRefView === CompactView) {
      return (
        <ReferenceCardCompact
          referenceNumber={referenceNumber}
          referenceBrand={referenceBrand}
          price={price}
          sparePartsView={sparePartsView}
          additionalInfo={additionalInfo}
          displayAdditionalInfo={displayAdditionalInfo}
          linkedReferences={linkedReferenceNumbers}
          isApplicableToCurrentVehicle={isApplicableToCurrentVehicle}
          firstRefWithPrice={firstRefWithPrice}
          firstRefWithLaborTimes={firstRefWithLaborTimes}
          firstRefWithPriceNotInCart={firstRefWithPriceNotInCart}
          handleAddToCartClick={handleAddToCartClick}
          renderLinkedReferencesSwitch={renderLinkedReferencesSwitch}
          linkedReferencesActive={linkedReferencesActive}
          handleAddToEstimateClick={handleAddToEstimateClick}
          displaySupersessionTooltip={displaySupersessionTooltip}
          availableForOrder={availableForOrder}
          genericPart={genericPart}
        />
      );
    }
    return (
      <ReferenceCard
        referenceNumber={referenceNumber}
        name={name}
        referenceBrand={referenceBrand}
        vehicleBrand={vehicleBrand}
        price={price}
        sparePartsView={sparePartsView}
        additionalInfo={additionalInfo}
        additionalInfoDisplayStyle={additionalInfoDisplayStyle}
        plateId={plateId}
        linkedReferences={linkedReferenceNumbers}
        isApplicableToCurrentVehicle={isApplicableToCurrentVehicle}
        firstRefWithPrice={firstRefWithPrice}
        firstRefWithPriceNotInCart={firstRefWithPriceNotInCart}
        refIndex={refIndex}
        displayPlateLink={displayPlateLink}
        seePlateState={seePlateState}
        handleAddToCartClick={handleAddToCartClick}
        renderLinkedReferencesSwitch={renderLinkedReferencesSwitch}
        linkedReferencesActive={linkedReferencesActive}
        handleAddToEstimateClick={handleAddToEstimateClick}
        displaySupersessionTooltip={displaySupersessionTooltip}
        availableForOrder={availableForOrder}
        genericPart={genericPart}
      />
    );
  };

  return <>{renderReferenceCard()}</>;
};
export default ReferenceCardWrapper;
