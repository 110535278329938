import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HeaderTranslationList } from '@1po/1po-bff-fe-spec/generated/catalog/maintenance_plan/response/GetMaintenancePlanResponse';
import { pdf } from '@react-pdf/renderer';
import { RootState } from 'app/AppStore';
import { getLastVehicleDetail, getMaintenancePlan } from 'domains/catalog/Catalog.store';
import { MaintenancePlanLocal } from 'domains/catalog/Catalog.types';
import { convertMileageToCurrentUnits } from 'domains/maintenancePlan/MaintenancePlan.mapper';
import {
  fetchCarMileageRequestSaga,
  getCarMileage,
  getPlannedOperations,
} from 'domains/maintenancePlan/MaintenancePlan.store';
import { mileage_units } from 'domains/maintenancePlan/MaintenancePlan.types';
import { useFetchSignature } from 'domains/pictures/Pictures.requests';
import { getDealerType } from 'domains/user';
import FluidsSection from 'pages/CatalogPage/DH/Maintenance/FluidsSection';
import MaintenanceHeader from 'pages/CatalogPage/DH/Maintenance/MaintenanceHeader';
import { MaintenancePdf } from 'pages/CatalogPage/DH/Maintenance/MaintenancePdf';
import MileageTimeSection from 'pages/CatalogPage/DH/Maintenance/MileageTimeSection';
import ServiceSection from 'pages/CatalogPage/DH/Maintenance/ServiceSection';
import SpecialInformation from 'pages/CatalogPage/DH/Maintenance/SpecialInformation';
import { Container, Space } from 'UI';
import { getData, hasData } from 'utils';

export interface MaintenanceDataProps {
  maintenancePlanData: MaintenancePlanLocal;
  getLabel: (id: number) => string;
  units?: mileage_units;
  mileage?: number;
  setMileage?: (mileage: number | undefined) => void;
  getMaintenanceStatus?: (id: string | undefined) => string;
}

const Maintenance = () => {
  const [mileage, setMileage] = useState<number | undefined>(undefined);
  const [units, setUnits] = useState<'km' | 'miles'>('km');
  const dispatch = useDispatch();
  const dealertype = useSelector(getDealerType);
  useEffect(() => {
    if (dealertype === 'R1' || dealertype === 'R2') {
      dispatch(fetchCarMileageRequestSaga());
    }
  }, [dispatch, dealertype]);

  const maintenancePlanData = getData(useSelector(getMaintenancePlan));
  const vehicleDetail = useSelector(getLastVehicleDetail);
  const mileageResponse = useSelector((state: RootState) => getCarMileage(state, vehicleDetail?.vin));
  const operationsResponse = useSelector((state: RootState) => getPlannedOperations(state, vehicleDetail?.vin));
  const operationsResponseData = getData(operationsResponse);

  useEffect(() => {
    if (!hasData(mileageResponse)) {
      return;
    }

    const mileageData = getData(mileageResponse);
    if (mileageData?.data?.isConnected === undefined) {
      return;
    }
    if (units === 'km' && mileageData?.data?.mileageUnit === 'km') {
      setMileage(mileageData?.data?.mileage);
    } else if (mileageData?.data?.mileageUnit) {
      const mileConverted = convertMileageToCurrentUnits(
        units,
        mileageData?.data?.mileageUnit,
        mileageData?.data?.mileage,
      );
      setMileage(mileConverted);
    } else {
      //
    }
  }, [mileageResponse, units]);

  const dhSignature = useFetchSignature('DATAHUB');
  if (!maintenancePlanData) {
    return <></>;
  }

  const getLabel = (id: number) => {
    const label = maintenancePlanData.headerTranslationsList.find((l: HeaderTranslationList) => l.id === id);
    return label?.label ?? '';
  };

  const getPdfBlob = async () => {
    return pdf(
      <MaintenancePdf
        data={maintenancePlanData}
        vehicleDetail={vehicleDetail}
        signature={dhSignature}
        mileage={mileage}
        mileageUnits={units}
        operations={operationsResponseData}
        displayOperations={dealertype === 'R1' || dealertype === 'R2'}
      />,
    ).toBlob();
  };

  const handlePrint = async () => {
    const pdfBlob = await getPdfBlob();

    const url = URL.createObjectURL(pdfBlob);
    if (url) {
      window.open(url);
    }
  };

  return (
    <Container>
      <MaintenanceHeader
        handlePrint={handlePrint}
        units={units}
        setUnits={setUnits}
        mileage={mileage}
        setMileage={setMileage}
      />
      <Space direction={'vertical'} size={60}>
        <MileageTimeSection
          maintenancePlanData={maintenancePlanData}
          getLabel={getLabel}
          units={units}
          mileage={mileage}
          setMileage={setMileage}
        />
        <ServiceSection maintenancePlanData={maintenancePlanData} getLabel={getLabel} />
        <SpecialInformation specialInformation={maintenancePlanData.specialInformationList} />
        <FluidsSection maintenancePlanData={maintenancePlanData} getLabel={getLabel} />
      </Space>
    </Container>
  );
};

export default Maintenance;
