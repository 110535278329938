import styled from 'styled-components';
import { Box, Flex } from 'UI';

export const EmptyPicture = styled(Box)`
  background-color: ${({ theme }) => theme.color.grey85};
`;

export const OptionWrapper = styled(Flex)`
  max-width: 500px;
  white-space: normal;
  word-break: break-word;
  align-content: center;
`;

export const CellWrapper = styled(Flex)<{ rowIndex?: number; columnIndex?: number }>`
  min-height: 46px;
  border-top: ${({ theme, rowIndex }) =>
    rowIndex !== undefined && rowIndex !== 0 ? `${theme.color.grey85} solid 1px` : undefined};
  border-left: ${({ theme, columnIndex }) => (columnIndex !== 0 ? `${theme.color.grey85} solid 1px` : undefined)};
  background-color: ${({ theme, rowIndex }) =>
    rowIndex !== undefined && rowIndex % 2 === 0 ? theme.color.grey95_light_110 : theme.color.grey100};
`;

export const TableWrapper = styled(Flex)`
  overflow: auto;
  box-shadow: 0 5px 10px 0 ${({ theme }) => theme.color.grey85};
  border-radius: 14px;
  border: solid 1px ${({ theme }) => theme.color.grey85};
`;

export const HeaderWrapper = styled(Box)`
  background-color: ${({ theme }) => theme.color.grey95_light_110};
  margin: -24px;
  padding: 24px;
  border-radius: 10px 10px 0 0;
  border-bottom: ${({ theme }) => `${theme.color.grey85} solid 1px`};
`;
