/* eslint-disable max-len */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AdditionalInformation } from '@1po/1po-bff-fe-spec/generated/catalog/references/common/model/AdditionalInformation';
import { ReferenceBrandType } from '@1po/1po-bff-fe-spec/generated/catalog/references/common/model/ReferenceBrandType';
import { VehicleBrandType } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { Space } from 'antd-v5';
import { v4 as uuidv4 } from 'uuid';
import { ROUTER_CATALOG_DH_L3, ROUTER_CATALOG_VEHICLE, ROUTER_PRODUCT } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { InfoCircleIcon } from 'assets/icons';
import { AddToEstimateButtonAndDialog } from 'components/AddToEstimate/AddToEstimateButtonAndDialog';
import { CopyToClipboardButton } from 'components/CopyToClipboardButton';
import { DataContainer, ErrorWithLabel } from 'components/DataContainer';
import { DiscountCorner } from 'components/Discount';
import { DocumentationAlertButton } from 'components/DocumentationAlertButton';
import { FirstHelpPopin } from 'components/Help/FirstHelpPopin';
import ProductModal from 'components/ProductModal';
import { ReferencePriceSection } from 'components/ReferencePriceSection';
import { DeliveryInformationUnavailable } from 'components/ReferenceUnavailableBox/DeliveryInformationUnavailable';
import {
  ReferenceUnavailable,
  ReferenceUnavailableForOrder,
} from 'components/ReferenceUnavailableBox/ReferenceUnavailable';
import StockDisplay from 'components/StockInfo';
import PrivateComponent from 'composers/PrivateComponent';
import { getLastSearchedVehicleKey, getLastVehicleDetail } from 'domains/catalog/Catalog.store';
import { DATAHUB, PlateIdParam, STANDARD } from 'domains/catalog/Catalog.types';
import { getCatalogSourceUrl } from 'domains/catalog/Catalog.utils';
import { useFetchLaborTimeIdsByReferenceGenericPart } from 'domains/laborTime/LaborTime.requests';
import { getLaborTimeIdsForGenericPart } from 'domains/laborTime/LaborTime.store';
import {
  ADDITIONAL_INFO_DISPLAY_STYLE,
  getDHReference,
  getIsStockAvailable,
  ReferencePriceType,
} from 'domains/references';
import { GarageView, SparePartsViewType, UserRole } from 'domains/user';
import { SupersessionTooltip } from 'pages/CatalogPage/common/ReferenceSection/SupersessionTooltip';
import { BrandImage } from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard/BrandImage';
import ElectronicPartsRepairLink from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard/ElectronicPartsRepairLink';
import LinkedReferences from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard/LinkedReferences/LinkedReferences';
import ReferenceCardLaborTimesWrapper from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard/ReferenceCardLaborTimesWrapper';
import Reuse from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard/Reuse';
import { theme } from 'styles';
import {
  Box,
  CenteredSpin,
  Flex,
  Icon,
  Link,
  LinkRoundButton,
  MarginBox,
  MinusCircleButton,
  PlusCircleButton,
  sentenceCase,
  Text,
} from 'UI';
import { ShowMoreButtonWrapper } from 'UI/Descriptions/Descriptions.styled';
import { getData, useExtraLarge } from 'utils';
import {
  AdditionalInfoExpandableWrapper,
  ReferenceImageContainer,
  SBrandImageBox,
  SCard,
} from './ReferenceCard.styled';
import { SeePlateLink } from './SeePlateLink';

export function mapAdditionalInfoValue(info: AdditionalInformation): string {
  if (!info.description) {
    return '';
  }
  if (!info.id) {
    return info.description;
  }
  return `${info.description} (${info.id})`;
}

interface ReferenceCardProps {
  name?: string;
  referenceNumber: string;
  referenceBrand?: ReferenceBrandType;
  vehicleBrand: VehicleBrandType;
  price?: ReferencePriceType;
  sparePartsView: SparePartsViewType;
  displayPlateLink?: boolean;
  additionalInfo?: AdditionalInformation[];
  additionalInfoDisplayStyle?: ADDITIONAL_INFO_DISPLAY_STYLE;
  plateId?: string;
  linkedReferences: string[];
  isApplicableToCurrentVehicle: boolean | undefined;
  seePlateState?: [string | undefined, (x: string | undefined) => void];
  firstRefWithPrice?: string;
  firstRefWithPriceNotInCart?: string;
  refIndex?: number;
  renderLinkedReferencesSwitch: () => React.ReactNode;
  handleAddToCartClick: () => void;
  handleAddToEstimateClick: () => void;
  linkedReferencesActive: boolean;
  displaySupersessionTooltip?: boolean;
  availableForOrder?: boolean;
  genericPart?: string;
}

const ReferenceCard = ({
  referenceNumber,
  name,
  referenceBrand,
  vehicleBrand,
  price,
  sparePartsView,
  displayPlateLink = false,
  additionalInfo,
  additionalInfoDisplayStyle = 'INSIDE_REFERENCE_CARD',
  linkedReferences,
  plateId,
  isApplicableToCurrentVehicle,
  seePlateState,
  firstRefWithPrice,
  firstRefWithPriceNotInCart,
  refIndex,
  genericPart,
  renderLinkedReferencesSwitch,
  handleAddToCartClick,
  linkedReferencesActive,
  handleAddToEstimateClick,
  displaySupersessionTooltip = false,
  availableForOrder = true,
}: ReferenceCardProps) => {
  const { t } = useTranslation();
  const vehicleDetail = useSelector(getLastVehicleDetail);
  const detail = useSelector((state: RootState) =>
    getDHReference(state, { vehicleKey: vehicleDetail?.vehicleKey, referenceNumber }),
  );
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const history = useHistory();
  const [laborTimesActive, setLaborTimesActive] = useState(false);
  const [showAdditionalInfoExpandable, setShowAdditionalInfoExpandable] = useState(false);
  const extraLarge = useExtraLarge();
  const availableInUserCountry = useSelector((state: RootState) => getIsStockAvailable(state, referenceNumber));

  useFetchLaborTimeIdsByReferenceGenericPart(referenceNumber, vehicleDetail?.vehicleKey);

  const laborTimeIdsWrapper = useSelector((state: RootState) =>
    getLaborTimeIdsForGenericPart(state, { vehicleKey, genericPart }),
  );

  if (!vehicleDetail) {
    return null;
  }

  const productLink = plateId
    ? `${getCatalogSourceUrl(vehicleDetail.catalogSource)}/${
        vehicleDetail.vehicleKey
      }${ROUTER_CATALOG_VEHICLE}${ROUTER_PRODUCT}/${referenceNumber}?${PlateIdParam}=${plateId}`
    : `${getCatalogSourceUrl(vehicleDetail.catalogSource)}/${
        vehicleDetail.vehicleKey
      }${ROUTER_CATALOG_VEHICLE}${ROUTER_PRODUCT}/${referenceNumber}`;

  const AdditionalInfoInsideCard = () => (
    <DataContainer data={additionalInfo}>
      {additionalInfo && additionalInfo.length > 0 && (
        <Flex
          minWidth={195}
          direction={'column'}
          align={'flex-start'}
          justify={'flex-end'}
          dataCy={'additional-information'}
        >
          <Flex
            direction={'column'}
            size={1}
            minWidth={80}
            maxWidth={290}
            maxHeight={150}
            justify={'center'}
            align={'flex-start'}
          >
            <Text type={availableInUserCountry ? 'section_bold' : 'light_14_bold_black_45'} disableGutter>
              {t('catalog.details.additional_information', 'Additional information')}
            </Text>
            <Text type={availableInUserCountry ? 'light_12_black_65' : 'light_12_black_45'}>
              {`${additionalInfo[0].title ? sentenceCase(additionalInfo[0].title.toLowerCase()) : ''}
                ${sentenceCase(mapAdditionalInfoValue(additionalInfo[0]).toLowerCase())} `}
            </Text>
            {additionalInfo && additionalInfo.length > 1 && (
              <Text type={availableInUserCountry ? 'light_12_black_65' : 'light_12_black_45'}>
                {`${additionalInfo[1].title ? sentenceCase(additionalInfo[1].title.toLowerCase()) : ''} ${sentenceCase(
                  mapAdditionalInfoValue(additionalInfo[1]).toLowerCase(),
                )}`}
              </Text>
            )}
            {availableInUserCountry && (
              <Text
                onClick={() => history.push(productLink)}
                type={'light_12'}
                displayStyle={'link'}
                cursor={'pointer'}
                decoration={'underline'}
              >
                {additionalInfo &&
                  additionalInfo.length > 2 &&
                  t('catalog.reference_card.additional_info.seeMore', 'See more...')}
              </Text>
            )}
            <MarginBox mr={20} mb={extraLarge ? 5 : 0} />
          </Flex>
        </Flex>
      )}
    </DataContainer>
  );

  const AdditionalInfoExpandable = ({
    showAdditionalInfo,
    setShowAdditionalInfo,
  }: {
    showAdditionalInfo: boolean;
    setShowAdditionalInfo: React.Dispatch<React.SetStateAction<boolean>>;
  }) => {
    const handleButtonClicked = () => {
      setShowAdditionalInfo(!showAdditionalInfo);
    };

    if (!additionalInfo || additionalInfo.length === 0) {
      return <></>;
    }

    return (
      <>
        {showAdditionalInfo ? (
          <>
            <AdditionalInfoExpandableWrapper>
              {additionalInfo.map((info) => (
                <>
                  <Flex key={`additional-info-${uuidv4()}`}>
                    <Icon IconComponent={InfoCircleIcon} color={theme.color.grey55} size={21} noPointer />
                    <MarginBox ml={45} />
                    <Text type={'light_14_bold_black_65'}>
                      {info.title ? sentenceCase(info.title.toLowerCase()) : ''}
                    </Text>
                    <MarginBox ml={25} />
                    <Text type={'light_14_black_65'}>{sentenceCase(mapAdditionalInfoValue(info).toLowerCase())}</Text>
                  </Flex>
                  <MarginBox mb={5} />
                </>
              ))}
            </AdditionalInfoExpandableWrapper>
            <ShowMoreButtonWrapper align={'center'} justify={'center'} maxHeight={0}>
              <MinusCircleButton onClick={handleButtonClicked} color={'grey55'} />
            </ShowMoreButtonWrapper>
          </>
        ) : (
          <ShowMoreButtonWrapper align={'center'} justify={'center'} maxHeight={0}>
            <PlusCircleButton onClick={handleButtonClicked} color={'ink_black'} />
          </ShowMoreButtonWrapper>
        )}
      </>
    );
  };

  const BrandImageWrapper = ({ children }: { children: React.ReactNode }) =>
    availableInUserCountry ? <>{children}</> : <SBrandImageBox>{children}</SBrandImageBox>;

  const renderAddLaborTimeLink = () => {
    return (
      <DataContainer
        data={laborTimeIdsWrapper?.searchStatus}
        Loading={() => (
          <Box width={85}>
            <CenteredSpin size={'small'} />
          </Box>
        )}
        NotFound={() => (
          <FirstHelpPopin
            streamId={ROUTER_CATALOG_DH_L3}
            popinId={`${ROUTER_CATALOG_DH_L3}_labor_times`}
            placement={'right'}
            skip={refIndex !== 0}
          >
            <Text type={'light_12'} displayStyle={'disabled'}>
              {t('common.action.add_labor_time', 'Add Labor time')}
            </Text>
          </FirstHelpPopin>
        )}
        ErrorState={() => (
          <ErrorWithLabel
            label={t('common.labor_times.backend_error.short', 'Labor times temporarily unavailable.')}
            narrow
          />
        )}
      >
        <FirstHelpPopin
          streamId={ROUTER_CATALOG_DH_L3}
          popinId={`${ROUTER_CATALOG_DH_L3}_labor_times`}
          placement={'right'}
          skip={refIndex !== 0}
        >
          <Text
            hoverUnderLine
            onClick={() => {
              setLaborTimesActive((prev) => !prev);
            }}
            type={'light_12'}
            displayStyle={'link'}
            cursor={'pointer'}
          >
            {t('common.action.add_labor_time', 'Add Labor time')}
          </Text>
        </FirstHelpPopin>
      </DataContainer>
    );
  };

  const seePlateComp = (
    <>
      {displayPlateLink && detail && seePlateState && (
        <SeePlateLink referenceNumber={referenceNumber} seePlateState={seePlateState} />
      )}
    </>
  );

  return (
    <>
      <SCard isSquared={laborTimesActive || !availableForOrder}>
        {sparePartsView === GarageView && <DiscountCorner reference={referenceNumber} type={'catalog'} />}
        <Flex direction={'row'}>
          <MarginBox mr={20}>
            <Box width={120} height={120}>
              <Link to={productLink}>
                <BrandImageWrapper>
                  <BrandImage referenceBrand={referenceBrand} vehicleBrand={vehicleBrand} />
                </BrandImageWrapper>
              </Link>
              {displaySupersessionTooltip && (
                <ProductModal
                  triggerComponent={
                    <ReferenceImageContainer>
                      <SupersessionTooltip>
                        <LinkRoundButton />
                      </SupersessionTooltip>
                    </ReferenceImageContainer>
                  }
                  referenceNumber={referenceNumber}
                  seePlate={seePlateComp}
                  scrollToSubstitutes
                />
              )}
            </Box>
          </MarginBox>
          <Flex>
            <MarginBox mr={20}>
              <Flex wrap={'wrap'}>
                <Flex size={1} minWidth={extraLarge ? 315 : 0} direction={'column'}>
                  <Flex align={'center'}>
                    <ProductModal
                      triggerComponent={
                        <Text
                          type={availableInUserCountry ? 'h2' : 'h2_black_45'}
                          disableGutter
                          hoverUnderLine
                          cursor={'pointer'}
                          dataCy={'link-reference-number'}
                        >
                          {t('catalog.reference_card.reference_number', 'Ref:')} {referenceNumber}
                        </Text>
                      }
                      referenceNumber={referenceNumber}
                      seePlate={seePlateComp}
                      isVehicleCatalog
                    />
                    <MarginBox mr={10} />
                    <CopyToClipboardButton
                      textToCopy={referenceNumber}
                      message={t(
                        'catalog.reference_card.reference_number.copied_to_clipboard',
                        'Reference number {{referenceNumber}} copied to clipboard',
                        { referenceNumber },
                      )}
                      tooltip={t('catalog.reference_card.reference_number.copy', 'Copy reference')}
                    />
                    {(referenceBrand === 'MOTRIO' || referenceBrand === 'IAM') && (
                      <>
                        <MarginBox mr={1} />
                        <DocumentationAlertButton
                          reference={getData(detail)}
                          vehicleDetail={vehicleDetail}
                          vehicleRegistrationNumber={vehicleDetail?.vrn ?? vehicleDetail?.vehicleKey}
                        />
                      </>
                    )}
                  </Flex>
                  <ProductModal
                    triggerComponent={
                      <Text
                        type={availableInUserCountry ? 'light_14_black_65' : 'light_14_bold_black_45'}
                        transform={'sentence-case'}
                        hoverUnderLine
                        cursor={'pointer'}
                        dataCy={'link-designation'}
                      >
                        {name}
                      </Text>
                    }
                    referenceNumber={referenceNumber}
                    seePlate={seePlateComp}
                    isVehicleCatalog
                  />
                  {seePlateComp}
                  <StockDisplay
                    vehicleKey={vehicleDetail?.vehicleKey}
                    referenceNumber={referenceNumber}
                    isApplicableToCurrentVehicle={isApplicableToCurrentVehicle}
                  />
                  <Reuse referenceNumber={referenceNumber} />
                  {!extraLarge && additionalInfoDisplayStyle === 'INSIDE_REFERENCE_CARD' && (
                    <AdditionalInfoInsideCard />
                  )}
                  {availableInUserCountry ? (
                    <Flex direction={'row'} justify={'space-between'} align={'center'}>
                      <Space>
                        {linkedReferences.length > 0 && renderLinkedReferencesSwitch()}
                        {renderAddLaborTimeLink()}
                        <ElectronicPartsRepairLink referenceNumber={referenceNumber} type={'light_12'} />
                      </Space>
                    </Flex>
                  ) : (
                    <DeliveryInformationUnavailable />
                  )}
                </Flex>
              </Flex>
            </MarginBox>
          </Flex>
          {extraLarge && additionalInfoDisplayStyle === 'INSIDE_REFERENCE_CARD' && <AdditionalInfoInsideCard />}
          {availableInUserCountry ? (
            <Flex direction={'row'} justify={'flex-end'}>
              <ReferencePriceSection
                referenceNumber={referenceNumber}
                sparePartsView={sparePartsView}
                handleAddToCartClick={handleAddToCartClick}
                vehicleKey={vehicleDetail?.vehicleKey}
                showFirstHelp={referenceNumber === firstRefWithPriceNotInCart}
                align={'left'}
                catalogSource={DATAHUB}
                referenceType={STANDARD}
                availableForOrder={availableForOrder}
                linkedReferences={linkedReferences}
              />
              {price?.garageView?.vatExcludedPrice && (
                <PrivateComponent
                  render={() => (
                    <FirstHelpPopin
                      streamId={ROUTER_CATALOG_DH_L3}
                      popinId={`${ROUTER_CATALOG_DH_L3}_add_to_estimate`}
                      placement={'left'}
                      skip={referenceNumber !== firstRefWithPrice}
                    >
                      <AddToEstimateButtonAndDialog
                        handleAddToEstimateClick={handleAddToEstimateClick}
                        displayed={availableForOrder}
                        isVehicleCatalog
                      />
                    </FirstHelpPopin>
                  )}
                  requiredRights={[UserRole.COMMAND, UserRole.CONNECT_COMMANDE]}
                />
              )}
            </Flex>
          ) : (
            <Flex minWidth={extraLarge ? 400 : 185} maxWidth={400} align={'center'} justify={'flex-end'}>
              <ReferenceUnavailable size={'medium'} />
            </Flex>
          )}
        </Flex>
        {linkedReferencesActive && (
          <>
            <Flex direction={'row'} dataCy={'linked-references'}>
              <LinkedReferences
                linkedReferences={[...linkedReferences].sort((a, b) => (a > b ? 1 : -1))}
                vehicleDetail={vehicleDetail}
                isApplicableToCurrentVehicle={isApplicableToCurrentVehicle}
                handleAddToCartClick={() => undefined}
                handleAddToEstimateClick={() => undefined}
                sparePartsView={sparePartsView}
              />
            </Flex>
          </>
        )}
      </SCard>
      {laborTimesActive && laborTimeIdsWrapper?.data && (
        <ReferenceCardLaborTimesWrapper
          referenceNumber={referenceNumber}
          laborTimeIds={laborTimeIdsWrapper?.data}
          isSquared={!availableForOrder}
        />
      )}
      <ReferenceUnavailableForOrder enabled={!availableForOrder} />
      {additionalInfoDisplayStyle === 'EXPANDABLE_WITH_PLUS_ICON' && (
        <AdditionalInfoExpandable
          showAdditionalInfo={showAdditionalInfoExpandable}
          setShowAdditionalInfo={setShowAdditionalInfoExpandable}
        />
      )}
      <MarginBox mt={15} />
    </>
  );
};
export default ReferenceCard;
