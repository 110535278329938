import React, { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';
import {
  AdditionalInformation,
  Origin,
  ReferenceBrandType,
  ReferenceSource,
} from '@1po/1po-bff-fe-spec/generated/catalog/text_search/model/IAMEquivalentReferences';
/* eslint-disable max-len */
import { Alert } from 'antd';
import { ROUTER_CART, ROUTER_ESTIMATE } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { trackAppEvent } from 'app/AppTracker';
import { AddToEstimateButton } from 'components/AddToEstimate';
import { AddToEstimateButtonAndDialog } from 'components/AddToEstimate/AddToEstimateButtonAndDialog';
import { ReferencePriceSection } from 'components/ReferencePriceSection';
import StockDisplay from 'components/StockInfo';
import { addReferenceByRefNumberRequest } from 'domains/basket/Basket.store';
import { getLastSearchedVehicleKey, getLastVehicleDetail } from 'domains/catalog/Catalog.store';
import { IAM, STANDARD } from 'domains/catalog/Catalog.types';
import { convertReferenceToEstimateReference } from 'domains/estimate/Estimate.mapper';
import { addCatalogReference, setSelectedTab } from 'domains/estimate/Estimate.store';
import { EstimateTabName } from 'domains/estimate/Estimate.types';
import { getIAMReference, getIAMReferences, getPrice, ReferencePriceType } from 'domains/references';
import { getDealerType, getSparePartsView } from 'domains/user';
import {
  CellWrapper,
  EmptyPicture,
  OptionWrapper,
  TableWrapper,
} from 'pages/CatalogPage/IAM/FullTextSearchResult/CompareTable.styled';
import { getDisplayName, getTranslatedDisplayName } from 'pages/CatalogPage/IAM/FullTextSearchResult/utils';
import { getFirstImageUrl } from 'pages/CatalogPage/IAM/SubcategorySection/SparePartsSection/ReferenceCardsContainer/PlateReferenceCard/IAMPlateReferenceCard';
import { addToEstimateNotification } from 'pages/CatalogPage/IAM/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard/IAMReferenceCard';
import { PictureSection } from 'pages/CatalogPage/IAM/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard/PictureSection';
import { theme } from 'styles';
import {
  Flex,
  FlexAlignItemsType,
  FlexJustifyType,
  GreyButton,
  MarginBox,
  NotificationLink,
  notifyTop,
  Select,
  SelectOptionSingle,
  Text,
} from 'UI';
import { BreakpointSelectable, getData, hasPrice } from 'utils';
import {
  TRACKING_EVENT_ADD_TO_ESTIMATE,
  TRACKING_EVENT_GO_TO_CART_SHORTCUT,
  TRACKING_EVENT_GO_TO_ESTIMATE,
} from 'utils/eventTracker/EventTracker.types';

export interface ComparativeTableReferences {
  firstReference: string;
  secondReference: string;
  thirdReference: string;
}

export interface RowProps extends TableCellRowPositionProps {
  selectedReferences?: string[];
}

export const DESCRIPTION_CELL_WIDTH = 250;
export const VALUE_CELL_WIDTH = 300;

type TableCellRowPositionProps = {
  rowIndex?: number;
};

type TableCellColumnPositionProps = {
  columnIndex?: number;
};

type TableCellPositionProps = TableCellRowPositionProps & TableCellColumnPositionProps;

type TableCellProps = {
  children: ReactNode;
  align?: BreakpointSelectable<FlexAlignItemsType>;
  justify?: BreakpointSelectable<FlexJustifyType>;
  minWidth?: BreakpointSelectable<string | number>;
  maxWidth?: BreakpointSelectable<string | number>;
} & TableCellPositionProps;

const TableCell = ({
  children,
  rowIndex,
  columnIndex,
  align = 'center',
  justify = 'center',
  minWidth,
  maxWidth,
}: TableCellProps) => {
  return (
    <CellWrapper
      rowIndex={rowIndex}
      columnIndex={columnIndex}
      align={align}
      justify={justify}
      minWidth={minWidth}
      maxWidth={maxWidth}
    >
      <MarginBox mx={16} my={12}>
        {children}
      </MarginBox>
    </CellWrapper>
  );
};

export const DescriptionCell = ({ description, rowIndex }: { description?: string } & TableCellRowPositionProps) => (
  <TableCell
    justify={'flex-start'}
    minWidth={DESCRIPTION_CELL_WIDTH}
    maxWidth={DESCRIPTION_CELL_WIDTH}
    rowIndex={rowIndex}
    columnIndex={0}
  >
    {description && <Text type={'text_dim_bold'}>{description}</Text>}
  </TableCell>
);

export const EmptyCell = ({ rowIndex, columnIndex }: TableCellPositionProps) => {
  return (
    <TableCell rowIndex={rowIndex} columnIndex={columnIndex} minWidth={VALUE_CELL_WIDTH}>
      <Text type={'light_14_black_65'}>{'-'}</Text>
    </TableCell>
  );
};

interface ReferencesForSelect {
  referenceNumber: string;
  translatedDisplayName: string;
}

interface SummaryRowProps extends RowProps {
  compareReferencesList?: string[];
  handleSelect?: (referenceNumber: string, columnIndex?: number) => void;
}

const SummaryRow = ({ selectedReferences, compareReferencesList, handleSelect, rowIndex }: SummaryRowProps) => {
  const { t } = useTranslation();

  const compareReferences = useSelector((state: RootState) =>
    getIAMReferences(state, { vehicleKey: undefined, referenceNumbers: compareReferencesList }),
  );

  const referencesForSelect: ReferencesForSelect[] = compareReferences.map((reference) => {
    return {
      referenceNumber: reference.referenceNumber,
      translatedDisplayName: getTranslatedDisplayName(
        t,
        reference.referenceNumber,
        reference.name,
        reference.designation,
        reference.supplierReferences,
        reference.supplier,
      ),
    };
  });
  return (
    <Flex direction={'row'}>
      <DescriptionCell rowIndex={rowIndex} />
      {selectedReferences?.map((reference, index) => {
        return (
          <SummaryCell
            key={`${index}_${reference}`}
            referenceNumber={reference}
            referencesForSelect={referencesForSelect}
            handleSelect={handleSelect}
            rowIndex={rowIndex}
            columnIndex={index + 1}
          />
        );
      })}
    </Flex>
  );
};

interface EstimateSectionProps {
  referenceNumber: string;
  displayName: string;
  sparePartsView: string;
  origin?: Origin;
  supplierCode?: string;
  referenceSource?: ReferenceSource;
  price?: ReferencePriceType;
  isOEM?: boolean;
  isMaintenance?: boolean;
  availableForOrder?: boolean;
  brand?: ReferenceBrandType;
}

export const EstimateSection = ({
  referenceNumber,
  displayName,
  brand,
  sparePartsView,
  origin,
  supplierCode,
  referenceSource,
  price,
  isOEM,
  isMaintenance,
  availableForOrder,
}: EstimateSectionProps) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleAddToEstimateClick = () => {
    if (price?.garageView?.vatExcludedPrice) {
      addToEstimateNotification(() => {
        trackAppEvent(TRACKING_EVENT_GO_TO_ESTIMATE);
        dispatch(setSelectedTab(EstimateTabName));
        history.push(`${ROUTER_ESTIMATE}`);
      });
      trackAppEvent(TRACKING_EVENT_ADD_TO_ESTIMATE);
      dispatch(
        addCatalogReference({
          reference: convertReferenceToEstimateReference(
            referenceNumber,
            displayName,
            price,
            true,
            'IAM',
            brand,
            origin,
            supplierCode,
            referenceSource,
          ),
        }),
      );
    }
  };

  return hasPrice(sparePartsView, price) && !isOEM && !isMaintenance ? (
    <AddToEstimateButtonAndDialog
      handleAddToEstimateClick={handleAddToEstimateClick}
      displayed={availableForOrder}
      size={'lg'}
      isVehicleCatalog
    />
  ) : null;
};

interface SummaryCellProps extends TableCellPositionProps {
  referenceNumber?: string;
  referencesForSelect?: ReferencesForSelect[];
  handleSelect?: (referenceNumber: string, columnIndex?: number) => void;
}

const SummaryCell = ({
  referenceNumber,
  referencesForSelect,
  rowIndex,
  columnIndex,
  handleSelect,
}: SummaryCellProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const reference = getData(
    useSelector((state: RootState) => getIAMReference(state, { vehicleKey: undefined, referenceNumber })),
  );
  const imageUrl = getFirstImageUrl(reference);
  const sparePartsView = useSelector(getSparePartsView);
  const isR1 = useSelector(getDealerType) === 'R1';
  const vehicleDetail = useSelector(getLastVehicleDetail);
  const availableForOrder = vehicleDetail?.vehicleBrand === 'ALPINE' ? isR1 : true;
  const price = getData(useSelector((state: RootState) => getPrice(state, referenceNumber)));

  const handleAddToCartClick = () => {
    if (referenceNumber) {
      notifyTop(
        'success',
        <Trans i18nKey={'catalog.universal_products.reference_card.added_to_basket.description'}>
          {'Universal product has been added to your cart'}
        </Trans>,
        undefined,
        <NotificationLink
          onClick={() => {
            trackAppEvent(TRACKING_EVENT_GO_TO_CART_SHORTCUT);
            history.push(ROUTER_CART);
          }}
        >
          <Trans i18nKey={'catalog.reference_card.added_to_basket.go_to_cart'}>{'Go to cart'}</Trans>
        </NotificationLink>,
      );
      dispatch(
        addReferenceByRefNumberRequest({
          reference: referenceNumber,
        }),
      );
    }
  };
  const mainReferenceName =
    columnIndex === 1
      ? reference &&
        getTranslatedDisplayName(
          t,
          reference.referenceNumber,
          reference.name,
          reference.designation,
          reference.supplierReferences,
          reference.supplier,
        )
      : undefined;

  const options = referencesForSelect?.map(
    (refForSelect) =>
      ({
        title: refForSelect.translatedDisplayName,
        value: refForSelect.referenceNumber,
      } as SelectOptionSingle),
  );

  return (
    <TableCell minWidth={VALUE_CELL_WIDTH} rowIndex={rowIndex} columnIndex={columnIndex}>
      <Flex minHeight={300} direction={'column'} justify={'center'} align={'center'} gap={20}>
        <Flex minHeight={150} maxHeight={150} align={'center'} justify={'center'}>
          {!referenceNumber ? (
            <EmptyPicture width={135} height={120} />
          ) : (
            <PictureSection
              documents={reference?.documents}
              brandImage={reference?.brandImageUrl}
              referenceImage={imageUrl}
              styles={{ imageBackground: theme.color.white }}
            />
          )}
        </Flex>
        <Flex align={'center'} justify={'center'} minWidth={300} minHeight={40} maxHeight={40}>
          {mainReferenceName ? (
            <Text type={'light_14_bold_black_85'}>{mainReferenceName}</Text>
          ) : (
            <Select
              notFoundContent={
                <Alert message={t('common.alert.no_matches_found', 'No matches found')} type={'error'} showIcon />
              }
              onChange={(refNumber: string) => {
                handleSelect?.(refNumber, columnIndex);
              }}
              value={referenceNumber === '' ? undefined : referenceNumber}
              options={options}
              optionWrapper={OptionWrapper}
              placeholder={t('catalog.iam.compare.select.placeholder', 'Select another equivalent product')}
              bordered
              search
              alignLeft
              size={'large'}
            />
          )}
        </Flex>

        {!reference ? (
          <Flex direction={'row'} minHeight={50} maxHeight={50} gap={12} align={'center'} justify={'center'}>
            <AddToEstimateButton
              handleAddToEstimateClick={() => {
                return;
              }}
              size={'lg'}
              disabled
            />
            <GreyButton
              size={'large'}
              onClick={() => {
                return;
              }}
              stretch
              shape={'round'}
              disabled
            >
              <Flex minWidth={160} align={'center'} justify={'center'} gap={5}>
                {t('cart.action.add_to_cart', 'Add to cart')}
              </Flex>
            </GreyButton>
          </Flex>
        ) : (
          <Flex direction={'row'} minHeight={50} maxHeight={50} gap={12} align={'center'} justify={'center'}>
            <ReferencePriceSection
              referenceNumber={reference.referenceNumber}
              handleAddToCartClick={handleAddToCartClick}
              catalogSource={IAM}
              referenceType={STANDARD}
              hidePrice
              buttonSize={'large'}
              buttonShape={'round'}
              availableForOrder={availableForOrder}
              externalButton={
                <EstimateSection
                  referenceNumber={reference.referenceNumber}
                  displayName={getDisplayName(
                    reference.name,
                    reference.designation,
                    reference.supplierReferences,
                    reference.supplier,
                  )}
                  sparePartsView={sparePartsView}
                  origin={reference.origin}
                  supplierCode={reference.supplierCode}
                  referenceSource={reference.referenceSource}
                  price={price}
                  availableForOrder={availableForOrder}
                  brand={reference.brand}
                />
              }
            />
          </Flex>
        )}

        <Flex />
      </Flex>
    </TableCell>
  );
};

const StocksRow = ({ selectedReferences, rowIndex }: RowProps) => {
  const { t } = useTranslation();
  const vehicleKey = useSelector(getLastSearchedVehicleKey);

  return (
    <Flex direction={'row'}>
      <DescriptionCell
        description={t('common.stock_and_availabilities', 'Stocks and availabilities')}
        rowIndex={rowIndex}
      />
      {selectedReferences?.map((reference, index) => {
        if (!reference) {
          return <EmptyCell key={`${index}_${reference}`} rowIndex={rowIndex} columnIndex={index + 1} />;
        }
        return (
          <TableCell
            key={`${index}_${reference}`}
            align={'flex-start'}
            minWidth={VALUE_CELL_WIDTH}
            rowIndex={rowIndex}
            columnIndex={index + 1}
          >
            <StockDisplay vehicleKey={vehicleKey} referenceNumber={reference} isApplicableToCurrentVehicle />
          </TableCell>
        );
      })}
    </Flex>
  );
};

const PriceRow = ({ selectedReferences, rowIndex }: RowProps) => {
  const { t } = useTranslation();

  return (
    <Flex direction={'row'}>
      <DescriptionCell description={t('common.price.title', 'Price')} rowIndex={rowIndex} />
      {selectedReferences?.map((reference, index) => (
        <PriceCell
          key={`${index}_${reference}`}
          referenceNumber={reference}
          rowIndex={rowIndex}
          columnIndex={index + 1}
        />
      ))}
    </Flex>
  );
};

interface PriceCellProps extends TableCellPositionProps {
  referenceNumber?: string;
}

const PriceCell = ({ referenceNumber, rowIndex, columnIndex }: PriceCellProps) => {
  const reference = getData(
    useSelector((state: RootState) => getIAMReference(state, { vehicleKey: undefined, referenceNumber })),
  );
  const sparePartsView = useSelector(getSparePartsView);

  if (!reference) {
    return <EmptyCell rowIndex={rowIndex} columnIndex={columnIndex} />;
  }
  return (
    <TableCell minWidth={VALUE_CELL_WIDTH} rowIndex={rowIndex} columnIndex={columnIndex}>
      {reference && (
        <ReferencePriceSection
          referenceNumber={reference.referenceNumber}
          handleAddToCartClick={() => {
            return;
          }}
          vehicleKey={undefined}
          sparePartsView={sparePartsView}
          catalogSource={IAM}
          referenceType={STANDARD}
          hideButtons
          narrow
        />
      )}
    </TableCell>
  );
};

interface AdditionalInfoRowProps extends TableCellRowPositionProps {
  selectedReferences: string[];
  additionalInformation?: AdditionalInformation;
}

const AdditionalInfoRow = ({ selectedReferences, additionalInformation, rowIndex }: AdditionalInfoRowProps) => {
  return (
    <Flex direction={'row'}>
      <DescriptionCell description={additionalInformation?.title} rowIndex={rowIndex} />
      {selectedReferences.map((reference, index) => (
        <AdditionalInfoCell
          key={`${index}_${reference}`}
          referenceNumber={reference}
          mainAdditionalInfo={additionalInformation}
          rowIndex={rowIndex}
          columnIndex={index + 1}
        />
      ))}
    </Flex>
  );
};

interface AdditionalInfoCellProps extends TableCellPositionProps {
  referenceNumber?: string;
  mainAdditionalInfo?: AdditionalInformation;
}

const AdditionalInfoCell = ({
  referenceNumber,
  mainAdditionalInfo,
  rowIndex,
  columnIndex,
}: AdditionalInfoCellProps) => {
  const reference = getData(
    useSelector((state: RootState) => getIAMReference(state, { vehicleKey: undefined, referenceNumber })),
  );

  const currentAdditionalInfo = reference?.additionalInformation?.find(
    (parameter) => parameter.id === mainAdditionalInfo?.id,
  );

  const isParameterEqualWithMainReference = mainAdditionalInfo?.description === currentAdditionalInfo?.description;

  if (!reference || !currentAdditionalInfo?.description) {
    return <EmptyCell rowIndex={rowIndex} columnIndex={columnIndex} />;
  }

  return (
    <TableCell minWidth={VALUE_CELL_WIDTH} rowIndex={rowIndex} columnIndex={columnIndex}>
      <Text type={isParameterEqualWithMainReference ? 'light_14_black_65' : 'link'}>
        {currentAdditionalInfo?.description}
      </Text>
    </TableCell>
  );
};

interface CompareTableProps {
  selectedReferences: ComparativeTableReferences;
  compareReferencesList?: string[];
  handleSelect?: (referenceNumber: string, columnIndex?: number) => void;
}

export const CompareTable = ({ selectedReferences, compareReferencesList, handleSelect }: CompareTableProps) => {
  const mainReference = getData(
    useSelector((state: RootState) =>
      getIAMReference(state, { vehicleKey: undefined, referenceNumber: selectedReferences.firstReference }),
    ),
  );

  const selectedReferencesArray = [
    selectedReferences.firstReference,
    selectedReferences.secondReference,
    selectedReferences.thirdReference,
  ];

  return (
    <TableWrapper>
      <Flex direction={'column'} minHeight={76}>
        <SummaryRow
          selectedReferences={selectedReferencesArray}
          compareReferencesList={compareReferencesList}
          handleSelect={handleSelect}
          rowIndex={0}
        />
        <StocksRow selectedReferences={selectedReferencesArray} rowIndex={1} />
        <PriceRow selectedReferences={selectedReferencesArray} rowIndex={2} />
        {mainReference?.additionalInformation?.map((additionalInformationParameter, index) => {
          return (
            <AdditionalInfoRow
              key={additionalInformationParameter.id}
              selectedReferences={selectedReferencesArray}
              additionalInformation={additionalInformationParameter}
              rowIndex={index + 3}
            />
          );
        })}
      </Flex>
    </TableWrapper>
  );
};
