import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { GetCarMileageResponse } from '@1po/1po-bff-fe-spec/generated/catalog/maintenance_plan/response/GetCarMileageResponse';
import { DealerType } from '@1po/1po-bff-fe-spec/generated/user/model/CommercialLink';
import { TFunction } from 'i18next';
import { Dispatch } from 'redux';
import styled from 'styled-components';
import { RootState } from 'app/AppStore';
import {
  CheckCircleIcon,
  ClockFullIcon,
  ExclamationCircleIcon,
  QuestionCircleIcon,
  SolidHistoryIcon,
} from 'assets/icons';
import { getLastVehicleDetail } from 'domains/catalog/Catalog.store';
import { convertMileageToCurrentUnits } from 'domains/maintenancePlan/MaintenancePlan.mapper';
import {
  fetchPlannedOperationsRequestSaga,
  getCarMileage,
  getPlannedOperations,
} from 'domains/maintenancePlan/MaintenancePlan.store';
import { mileage_units, MILES_TO_KM } from 'domains/maintenancePlan/MaintenancePlan.types';
import { getDealerType } from 'domains/user';
import { MaintenanceDataProps } from 'pages/CatalogPage/DH/Maintenance/Maintenance';
import { MilageIntervalTable } from 'pages/CatalogPage/DH/Maintenance/Maintenance.styled';
import { theme } from 'styles';
import {
  Box,
  CenterFlex,
  CleverInput,
  Flex,
  Icon,
  MarginBox,
  notifyTop,
  SmallSquareButton,
  Text,
  WithTooltip,
} from 'UI';
import { getCondArrayItem, getData, hasData, SearchData, textFormatter } from 'utils';

const Title = ({ children }: { children: string }) => (
  <Text type={'h6'} disableGutter transform={'capitalize'}>
    {children}
  </Text>
);

export const formatYear = (year: string) => {
  const formattedYear = textFormatter.formatNumber(Number(year), 1);
  if (formattedYear === '0' || formattedYear === '') {
    return '.';
  }
  return formattedYear;
};

export const SInput = styled(CleverInput)<{ isHighlighted?: boolean }>`
  height: 40px;
  width: 80px;
  border-color: ${({ isHighlighted }) => (isHighlighted ? theme.color.red : null)};
`;

const getColumns = (
  getLabel: (id: number) => string,
  t: TFunction,
  unitsKm: mileage_units,
  dispatch: Dispatch,
  mileage?: number | undefined,
  setMileage?: (mileage: number | undefined) => void,
  mileageResponse?: SearchData<GetCarMileageResponse> | undefined,
  dealerType?: DealerType,
) => {
  function onMileageBlur(val: string) {
    if (!hasData(mileageResponse) || mileageResponse.data?.isConnected !== 0) {
      setMileage && setMileage(Number.parseInt(val, 10));
      return;
    }
    const apiUnits = mileageResponse.data?.mileageUnit;
    const apiMileage = mileageResponse.data?.mileage;
    if (apiMileage > 0) {
      const apiMileageToCurrentUnits = convertMileageToCurrentUnits(unitsKm, apiUnits, apiMileage);
      if (Number.parseInt(val, 10) > apiMileageToCurrentUnits) {
        setMileage && setMileage(Number.parseInt(val, 10));
      } else {
        notifyTop(
          'warning',
          t(
            'catalog.maintenance.mileage_entered_higher_than_initial',
            'The mileage value entered must not be less than the initial value',
          ),
        );
        setMileage && setMileage(apiMileageToCurrentUnits);
      }
    } else {
      setMileage && setMileage(Number.parseInt(val, 10));
    }
  }

  return [
    {
      title: <Title>{t('catalog.maintenance.operations', 'Operations')}</Title>,
      children: [
        {
          dataIndex: 'label',
          key: 'label',
          colspan: 2,
          className: 'whiteBg',
        },
        {
          dataIndex: 'label2',
          key: 'label2',
          colspan: 0,
          className: 'whiteBg',
        },
      ],
    },
    {
      title: <Title>{getLabel(50)}</Title>,
      children: [
        {
          title: <Title>{getLabel(16)}</Title>,
          dataIndex: 'fromKm',
          key: 'fromKm',
          width: 200,
          render: function renderFromKm(fromKm: number) {
            return <CenterFlex>{fromKm || '.'}</CenterFlex>;
          },
          className: 'whiteBg',
        },
        {
          title: <Title>{getLabel(10)}</Title>,
          dataIndex: 'fromYears',
          key: 'fromYears',
          width: 200,
          render: function renderFromKm(fromYears: string) {
            return <CenterFlex>{formatYear(fromYears)}</CenterFlex>;
          },
          className: 'whiteBg',
        },
      ],
    },
    {
      title: <Title>{`${getLabel(51)} ${getLabel(40)}`}</Title>,
      children: [
        {
          title: <Title>{getLabel(16)}</Title>,
          dataIndex: 'everyKm',
          key: 'everyKm',
          width: 200,
          render: function renderFromKm(everyKm: number) {
            return <CenterFlex>{everyKm || '.'}</CenterFlex>;
          },
          className: 'whiteBg',
        },
        {
          title: <Title>{getLabel(10)}</Title>,
          dataIndex: 'everyYears',
          key: 'everyYears',
          width: 200,
          render: function renderFromKm(everyYears: string) {
            return <CenterFlex>{formatYear(everyYears)}</CenterFlex>;
          },
          className: 'whiteBg',
        },
      ],
    },
    ...getCondArrayItem(
      (dealerType === 'R1' || dealerType === 'R2') && {
        title: <Title>{t('catalog.maintenance.planned_maintenance', 'Planned maintenance')}</Title>,
        children: [
          {
            title: (
              <Flex align={'center'} justify={'flex-end'}>
                <Title>
                  {`${
                    unitsKm === 'km'
                      ? t('catalog.maintenance.units_km', 'Km')
                      : t('catalog.maintenance.units_miles', 'Miles')
                  }:`}
                </Title>
                <MarginBox mr={10} />
                <SInput
                  maxLength={7}
                  onBlur={onMileageBlur}
                  isHighlighted={false}
                  bordered
                  placeholder={t('catalog.maintenance.mileage', 'Mileage')}
                  value={mileage ? String(mileage) : '0'}
                />
                <MarginBox mr={10} />
                <SmallSquareButton
                  disabled={false}
                  onClick={() => {
                    if (mileage && (dealerType === 'R1' || dealerType === 'R2')) {
                      const mileageKm = unitsKm === 'km' ? mileage : Math.ceil(mileage * MILES_TO_KM);
                      dispatch(fetchPlannedOperationsRequestSaga({ mileageKm }));
                    }
                  }}
                  icon={
                    <WithTooltip title={t('catalog.maintenance.refresh_mileage', 'Refresh mileage')}>
                      <Icon IconComponent={SolidHistoryIcon} disabled={false} size={24} />
                    </WithTooltip>
                  }
                ></SmallSquareButton>
                <MarginBox mr={20} />
              </Flex>
            ),
            dataIndex: 'status',
            key: 'status',
            width: 260,
            render: function plannedMaintenance(status: number) {
              const icon = getIcon(status);
              const color = getColor(status);
              const title = getTitle(t, status);
              return (
                <CenterFlex>
                  <WithTooltip title={title}>
                    {icon && <Icon IconComponent={icon} color={color} background={'transparent'} size={24} />}
                  </WithTooltip>
                </CenterFlex>
              );
            },
            className: 'whiteBg',
          },
        ],
      },
    ),
  ];
};

const getIcon = (status: number | undefined) => {
  switch (status) {
    case 0:
      return CheckCircleIcon; //PictureCheckCircle
    case 1:
      return ExclamationCircleIcon;
    case 2:
      return ClockFullIcon;
    default:
      return QuestionCircleIcon;
  }
};

const getColor = (status: number | undefined) => {
  switch (status) {
    case 0:
      return theme.color.selected;
    case 1:
      return theme.color.warning;
    case 2:
      return theme.color.info;
    default:
      return theme.color.white;
  }
};

const getTitle = (t: TFunction, status: number | undefined) => {
  switch (status) {
    case 0:
      return t('catalog.maintenance.state.operation_updated', 'Operation updated, to be done later');
    case 1:
      return t('catalog.maintenance.state.operation_todo', 'Operation to do');
    case 2:
      return t('catalog.maintenance.state.operation_to_catchup', 'Operation to catch up');
    default:
      return t('catalog.maintenance.state.unknown_state', 'Unknown state');
  }
};

const MileageTimeSection = ({ maintenancePlanData, getLabel, units, mileage, setMileage }: MaintenanceDataProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const vehicleDetail = useSelector(getLastVehicleDetail);
  const operationsResponse = useSelector((state: RootState) => getPlannedOperations(state, vehicleDetail?.vin));
  const mileageResponse = useSelector((state: RootState) => getCarMileage(state, vehicleDetail?.vin));
  const unitsKm = units ?? 'km';
  const dealerType = useSelector(getDealerType);

  useEffect(() => {
    if (mileage && (dealerType === 'R1' || dealerType === 'R2')) {
      const mileageKm = unitsKm === 'km' ? mileage : Math.ceil(mileage * MILES_TO_KM);
      dispatch(fetchPlannedOperationsRequestSaga({ mileageKm }));
    }
  }, [mileageResponse, dispatch, mileage, unitsKm, dealerType]);

  if (maintenancePlanData.operationByMileageOrTimeList.length === 0) {
    return <></>;
  }
  const columns = getColumns(getLabel, t, unitsKm, dispatch, mileage, setMileage, mileageResponse, dealerType);
  const operationsResponseData = getData(operationsResponse);

  return (
    <>
      <Flex>
        <Flex direction={'column'}>
          <Text type={'h5_bold'}>{getLabel(27)}</Text>
          <Box height={17} />
          <Text type={'text_dim'}>{getLabel(26)}</Text>
        </Flex>
      </Flex>
      <Box height={33} />
      <MilageIntervalTable
        rowKey={'id'}
        columns={columns}
        dataSource={maintenancePlanData.operationByMileageOrTimeList.map((plan) => {
          return {
            ...plan,
            status1: plan.codeAction + plan.codeLibele,
            status: operationsResponseData?.data?.operations?.find((o) => o.code === plan.codeAction + plan.codeLibele)
              ?.isOpen,
          };
        })}
        pagination={false}
      />
    </>
  );
};

export default MileageTimeSection;
