import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Select } from 'antd';
import { validateProductsRequestSaga } from 'domains/promotion/Promotion.store';
import {
  CFSectionLocal,
  CreatePromotionModel,
  PromotionItem,
  PromotionTypeLocal,
} from 'domains/promotion/Promotion.types';
import { Flex, MarginBox, Text } from 'UI';
import { compareArrays } from 'utils';
import { hasLocalCommercialFamiliesCodeDuplicates } from './PromotionErrorNotifications';
import { DefaultTag, DisabledTag, ErrorTag } from '../CreatePromotion.styled';

const MAX_COMMERCIAL_FAMILY_CODES = 20;

const createNewCommercialFamilyCodes = (
  commercialFamilyCodes: CFSectionLocal[] | undefined,
  index: number,
  removedItem: PromotionItem | undefined,
  newItem: string,
  containsLocalDuplicate: boolean,
): CFSectionLocal[] | undefined => {
  return commercialFamilyCodes?.map((c, i) => {
    if (i === index) {
      if (removedItem) {
        const cfCodes = c.items.filter((item) => item.item !== removedItem.item);
        return { ...c, items: cfCodes };
      }
      return {
        ...c,
        items: [...c.items, { item: newItem, removable: true, alreadyLocallyPromoted: containsLocalDuplicate }],
      };
    }
    return c;
  });
};

export function updateLastLocallyPromotedCFCode(
  commercialFamilyCodes: CFSectionLocal[] | undefined,
  code: string | undefined,
): CFSectionLocal[] | undefined {
  if (!code) {
    return commercialFamilyCodes;
  }
  const occurrences = commercialFamilyCodes?.reduce((count, section) => {
    const filteredItem = section.items.filter((item) => item.item === code);
    count += filteredItem.length;
    return count;
  }, 0);
  if (occurrences === 1) {
    return commercialFamilyCodes?.map((section) => {
      return {
        ...section,
        items: section.items.map((item) => {
          if (item.item === code) {
            return {
              ...item,
              alreadyLocallyPromoted: false,
            };
          }
          return {
            ...item,
          };
        }),
      };
    });
  } else {
    return commercialFamilyCodes;
  }
}

interface CustomTagProps {
  label: React.ReactNode;
  onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const TagComponent = ({
  promotionType,
  label,
  item,
  isRemovable,
  onClose,
}: {
  promotionType: PromotionTypeLocal;
  label: React.ReactNode;
  item: PromotionItem | undefined;
  isRemovable: boolean;
  onClose: () => void;
}) => {
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return !isRemovable ? (
    <DisabledTag onMouseDown={onPreventMouseDown} closable={isRemovable} onClose={onClose}>
      <Text type={'text'}>{label}</Text>
    </DisabledTag>
  ) : item && promotionType !== 'BANNER' && (item.alreadyPromoted || item.alreadyLocallyPromoted) ? (
    <ErrorTag onMouseDown={onPreventMouseDown} closable={isRemovable} onClose={onClose}>
      <Text type={'text'}>{label}</Text>
    </ErrorTag>
  ) : (
    <DefaultTag onMouseDown={onPreventMouseDown} closable={isRemovable} onClose={onClose}>
      <Text type={'text'}>{label}</Text>
    </DefaultTag>
  );
};

export function CommercialFamiliesInput({
  promotion,
  setPromotion,
}: {
  promotion: CreatePromotionModel;
  setPromotion: (x: CreatePromotionModel) => void;
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const commercialFamilyCodesSections = useMemo(() => promotion.commercialFamilyCodes ?? [{ items: [], discount: 0 }], [
    promotion,
  ]);

  const notEditableCommercialFamilyCodeCount = useMemo(
    () => promotion.commercialFamilyCodes?.map((section) => section.items.filter((code) => !code.removable).length),
    [promotion],
  );
  const updateCommercialFamilyCodes = (newItems: string[], index: number) => {
    if (newItems.length > MAX_COMMERCIAL_FAMILY_CODES) {
      return;
    }
    const sectionLocal = promotion.commercialFamilyCodes && promotion.commercialFamilyCodes[index];
    const currentCodes = sectionLocal?.items;
    const newItem = newItems.filter((input) => !currentCodes?.find((code) => input === code.item))[0];
    const containsLocalDuplicate = hasLocalCommercialFamiliesCodeDuplicates(
      newItem,
      promotion.commercialFamilyCodes?.filter((_item, id) => id !== index),
    );

    if (newItem && currentCodes?.find((code) => code.item.toUpperCase() === newItem.toUpperCase())) {
      return;
    }

    const removedItem = sectionLocal?.items.find((input) => !newItems.find((code) => input.item === code));

    const updatedCommercialFamilyCodes = createNewCommercialFamilyCodes(
      promotion.commercialFamilyCodes,
      index,
      removedItem,
      newItem,
      containsLocalDuplicate,
    );
    const newCommercialFamilyCodes = updateLastLocallyPromotedCFCode(updatedCommercialFamilyCodes, removedItem?.item);

    const notEditableCodeCount = notEditableCommercialFamilyCodeCount && notEditableCommercialFamilyCodeCount[index];

    const updatedPromotion: CreatePromotionModel = {
      ...promotion,
      commercialFamilyCodes: newCommercialFamilyCodes ?? [
        {
          items: newItems.map((item) => {
            return { item, removable: true };
          }),
          discount: 0,
          editing: false,
        },
      ],
    };

    if (
      newCommercialFamilyCodes &&
      notEditableCodeCount &&
      newCommercialFamilyCodes.length &&
      newCommercialFamilyCodes[0]?.items.length < notEditableCodeCount
    ) {
      newCommercialFamilyCodes?.pop();
    } else {
      setPromotion(updatedPromotion);
    }

    if (!containsLocalDuplicate) {
      dispatch(validateProductsRequestSaga({ promotion: updatedPromotion }));
    }
  };

  return (
    <>
      <Flex direction={'column'} size={4}>
        <Text type={'h5_bold'}>{t('backoffice.promotion.by_commercial_family', 'By commercial family')}</Text>
        {commercialFamilyCodesSections &&
          commercialFamilyCodesSections?.map((section: CFSectionLocal, index: number) => {
            const inputValue = section?.items.map((item) => item.item);
            return (
              <React.Fragment key={'promotion_commercial_family_codes_' + index}>
                <MarginBox mt={20} key={'promotion_commercial_family_codes_margin_' + index} />
                <Select
                  mode={'tags'}
                  key={'promotion_commercial_family_codes_input_' + index}
                  value={inputValue}
                  open={false}
                  tagRender={(props: CustomTagProps) => {
                    // eslint-disable-next-line react/prop-types
                    const { label, onClose } = props;
                    const item = promotion.commercialFamilyCodes
                      ? promotion.commercialFamilyCodes[index].items.find((code) => code.item === label)
                      : undefined;
                    const isRemovable = item ? item.removable : true;
                    return (
                      <TagComponent
                        promotionType={promotion.promotionType}
                        label={label}
                        item={item}
                        isRemovable={isRemovable}
                        onClose={onClose}
                      />
                    );
                  }}
                  onChange={(newItems: string[]) => {
                    const upperCaseItems = newItems.map((x) => x.toUpperCase());
                    const filteredItems = upperCaseItems.filter((item, idx) => upperCaseItems.indexOf(item) === idx);
                    if (compareArrays(upperCaseItems, filteredItems)) {
                      updateCommercialFamilyCodes(filteredItems, index);
                    }
                  }}
                  tokenSeparators={[' ', ',']}
                  placeholder={''}
                  bordered
                />
              </React.Fragment>
            );
          })}
      </Flex>
    </>
  );
}
