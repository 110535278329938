import styled from 'styled-components';
import { CenterFlex, Flex } from 'UI';

export const IconsBackground = styled.div<{ selected: boolean }>`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: ${({ selected, theme }) => (selected ? theme.color.green_dark : theme.color.grey95)};
  cursor: pointer;

  :hover {
    transform: scale(1.2);
  }
`;

export const SelectedItems = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: ${({ theme }) => theme.color.selected_dark};
`;

export const ReferenceCardWrapper = styled(Flex)<{ index?: number }>`
  border-radius: 4px;
  border: dashed 1px #bfbfbf;
  margin-top: ${({ index }) => (index ? '16px' : '0px')};
  height: 96px;
`;

export const SectionPicBackground = styled(CenterFlex)<{
  showBackground?: boolean;
  height: number;
  width: number;
  isSmall?: boolean;
}>`
  border-radius: ${({ isSmall }) => (isSmall ? '50%' : '4px')};
  width: ${({ width }) => width + 'px'};
  height: ${({ height }) => height + 'px'};
  background-color: ${({ theme, showBackground }) => (showBackground ? theme.color.blue_dark_grey : 'white')};
  overflow: hidden;
`;

export const ReferencePicBackground = styled(CenterFlex)<{ showBackground?: boolean }>`
  border-radius: 4px 0px 0px 4px;
  width: 128px;
  height: 94px;
  background-color: ${({ theme, showBackground }) => (showBackground ? theme.color.blue_grey_2 : 'white')};
  overflow: hidden;
`;
