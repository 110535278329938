/* eslint-disable max-len */
import { GET_REPAIR_ORDER, SEND_ESTIMATE_TO_DMS } from '@1po/1po-bff-fe-spec/generated/common/WebsocketConstants';
import { GetRepairOrderRequest } from '@1po/1po-bff-fe-spec/generated/dms/request/GetRepairOrderRequest';
import { SendEstimateToDMSRequest } from '@1po/1po-bff-fe-spec/generated/dms/request/SendEstimateToDMSRequest';

import {
  ADD_BUNDLES_FROM_AUTOCOMPLETE_URL,
  ADD_BUNDLES_FROM_MY_STORE_URL,
  ADD_CATALOG_LABOR_TIME_BY_CODE_URL,
  ADD_CATALOG_LABOR_TIME_URL,
  ADD_CATALOG_REFERENCE_BY_NUMBER_URL,
  ADD_CATALOG_REFERENCE_URL,
  ADD_CATALOG_REFERENCES_TO_BASKET_URL,
  ADD_CATALOG_TIRE_BY_NUMBER_URL,
  ADD_CATALOG_TIRE_URL,
  ADD_CUSTOM_ITEM_URL,
  ADD_REFERENCE_NUMBER_URL,
  ADD_SETTINGS_ITEM_URL,
  ADD_VEHICLE_URL,
  CANCEL_DELETION,
  CHECKOUT_B2B_ESTIMATE_URL,
  CLEAR_ITEMS_URL,
  DELETE_ESTIMATE,
  ESTIMATE_FROM_MAINTENANCE_PLAN,
  GET_BY_ID_URL,
  GET_HISTORY_URL,
  GET_LATEST_URL,
  GET_REFERENCES_PRICE_URL,
  GET_REFERENCES_STOCKS_URL,
  GET_SETTINGS_URL,
  QUERY_BUNDLES_AUTOCOMPLETE_URL,
  REMOVE_ITEM_URL,
  REMOVE_SETTINGS_ITEM_URL,
  UPDATE_CLIENT_URL,
  UPDATE_COUNTER_PARTIES_URL,
  UPDATE_FREE_BUNDLE_URL,
  UPDATE_LABOR_TIME_URL,
  UPDATE_OBSERVATIONS_URL,
  UPDATE_OTHER_ITEM_URL,
  UPDATE_REFERENCE_URL,
  UPDATE_SETTINGS_URL,
  UPDATE_SETTINGS_WASTE_RECYCLING_URL,
  UPDATE_TIRE_URL,
  UPDATE_VEHICLE_URL,
  UPDATE_WASTE_RECYCLING_URL,
} from '@1po/1po-bff-fe-spec/generated/estimate/EstimateURLs';
import { AddCatalogReferencesToBasket } from '@1po/1po-bff-fe-spec/generated/estimate/request/AddCatalogReferencesToBasket';
import { AddCustomItem } from '@1po/1po-bff-fe-spec/generated/estimate/request/AddCustomItem';
import { AddFreeBundlesFromAutocomplete } from '@1po/1po-bff-fe-spec/generated/estimate/request/AddFreeBundleFromAutocomplete';
import { AddFreeBundlesFromMyStore } from '@1po/1po-bff-fe-spec/generated/estimate/request/AddFreeBundlesFromMyStore';
import { AddFromMaintenancePlan } from '@1po/1po-bff-fe-spec/generated/estimate/request/AddFromMaintenancePlan';
import { AddItemByReferenceNumber } from '@1po/1po-bff-fe-spec/generated/estimate/request/AddItemByReferenceNumber';
import { AddLaborTimeByCode } from '@1po/1po-bff-fe-spec/generated/estimate/request/AddLaborTimeByCode';
import { AddLaborTimeFromCatalog } from '@1po/1po-bff-fe-spec/generated/estimate/request/AddLaborTimeFromCatalog';
import { AddReferenceFromCatalog } from '@1po/1po-bff-fe-spec/generated/estimate/request/AddReferenceFromCatalog';
import { AddReferenceNumber } from '@1po/1po-bff-fe-spec/generated/estimate/request/AddReferenceNumber';
import { AddSettingsItem } from '@1po/1po-bff-fe-spec/generated/estimate/request/AddSettingsItem';
import { AddTireFromCatalog } from '@1po/1po-bff-fe-spec/generated/estimate/request/AddTireFromCatalog';
import { AddVehicle } from '@1po/1po-bff-fe-spec/generated/estimate/request/AddVehicle';
import { AutocompleteSearchBundles } from '@1po/1po-bff-fe-spec/generated/estimate/request/AutocompleteSearchBundles';
import { ClearItems } from '@1po/1po-bff-fe-spec/generated/estimate/request/ClearItems';
import { DeleteEstimate } from '@1po/1po-bff-fe-spec/generated/estimate/request/DeleteEstimate';
import { GetEstimate } from '@1po/1po-bff-fe-spec/generated/estimate/request/GetEstimate';
import { GetEstimateHistory } from '@1po/1po-bff-fe-spec/generated/estimate/request/GetEstimateHistory';
import { GetLatestEstimate } from '@1po/1po-bff-fe-spec/generated/estimate/request/GetLatestEstimate';
import {
  GetReferenceStocksForB2BEstimateRequest,
  ReferenceStockTradingDataRequestDetail,
  UserContext,
} from '@1po/1po-bff-fe-spec/generated/estimate/request/GetReferenceStocksForB2BEstimateRequest';
import {
  GetReferenceTradingDataForB2BEstimateRequest,
  ReferenceTradingDataRequestDetail,
} from '@1po/1po-bff-fe-spec/generated/estimate/request/GetReferenceTradingDataForB2BEstimateRequest';

import { RemoveItem } from '@1po/1po-bff-fe-spec/generated/estimate/request/RemoveItem';
import { RemoveSettingsItem } from '@1po/1po-bff-fe-spec/generated/estimate/request/RemoveSettingsItem';
import { UpdateB2BCounterParties } from '@1po/1po-bff-fe-spec/generated/estimate/request/UpdateB2BCounterParties';
import { UpdateClient } from '@1po/1po-bff-fe-spec/generated/estimate/request/UpdateClient';
import { UpdateFreeBundle } from '@1po/1po-bff-fe-spec/generated/estimate/request/UpdateFreeBundle';
import { UpdateLaborTime } from '@1po/1po-bff-fe-spec/generated/estimate/request/UpdateLaborTime';
import { UpdateObservations } from '@1po/1po-bff-fe-spec/generated/estimate/request/UpdateObservations';
import { UpdateOtherItem } from '@1po/1po-bff-fe-spec/generated/estimate/request/UpdateOtherItem';
import { UpdateReference } from '@1po/1po-bff-fe-spec/generated/estimate/request/UpdateReference';
import { UpdateSettings } from '@1po/1po-bff-fe-spec/generated/estimate/request/UpdateSettings';
// eslint-disable-next-line max-len
import { UpdateWasteRecycling as UpdateWasteRecyclingSettings } from '@1po/1po-bff-fe-spec/generated/estimate/request/UpdateSettingsWasteRecycling';
import { UpdateVehicle } from '@1po/1po-bff-fe-spec/generated/estimate/request/UpdateVehicle';
import { UpdateWasteRecycling } from '@1po/1po-bff-fe-spec/generated/estimate/request/UpdateWasteRecycling';
import { CheckoutB2BEstimate } from '@1po/1po-bff-fe-spec/generated/order/request/CheckoutB2BEstimate';
import { WebSocketAction } from 'utils/domainStore';
import { wsSendAction, wsSendActionNoTimeout } from 'utils/domainStore/api';

export function sendGetLatestEstimate(request: GetLatestEstimate): WebSocketAction {
  return wsSendAction(GET_LATEST_URL, request);
}

export function sendGetHistory(request: GetEstimateHistory): WebSocketAction {
  return wsSendAction(GET_HISTORY_URL, request);
}

export function sendGetEstimateById(request: GetEstimate): WebSocketAction {
  return wsSendAction(GET_BY_ID_URL, request);
}

export function sendAddCatalogLaborTime(request: AddLaborTimeFromCatalog): WebSocketAction {
  return wsSendAction(ADD_CATALOG_LABOR_TIME_URL, request);
}

export function sendAddCatalogReference(request: AddReferenceFromCatalog): WebSocketAction {
  return wsSendAction(ADD_CATALOG_REFERENCE_URL, request);
}

export function sendAddTire(request: AddTireFromCatalog): WebSocketAction {
  return wsSendAction(ADD_CATALOG_TIRE_URL, request);
}

export function sendAddCustomItem(request: AddCustomItem): WebSocketAction {
  return wsSendAction(ADD_CUSTOM_ITEM_URL, request);
}

export function sendAddReferenceNumber(request: AddReferenceNumber): WebSocketAction {
  return wsSendAction(ADD_REFERENCE_NUMBER_URL, request);
}

export function sendUpdateReference(request: UpdateReference): WebSocketAction {
  return wsSendActionNoTimeout(UPDATE_REFERENCE_URL, request);
}

export function sendUpdateLaborTime(request: UpdateLaborTime): WebSocketAction {
  return wsSendActionNoTimeout(UPDATE_LABOR_TIME_URL, request);
}

export function sendUpdateTire(request: UpdateReference): WebSocketAction {
  return wsSendActionNoTimeout(UPDATE_TIRE_URL, request);
}

export function sendUpdateOtherItem(request: UpdateOtherItem): WebSocketAction {
  return wsSendActionNoTimeout(UPDATE_OTHER_ITEM_URL, request);
}

export function sendUpdateFreeBundle(request: UpdateFreeBundle): WebSocketAction {
  return wsSendActionNoTimeout(UPDATE_FREE_BUNDLE_URL, request);
}

export function sendAddBundlesFromMyStore(request: AddFreeBundlesFromMyStore): WebSocketAction {
  return wsSendAction(ADD_BUNDLES_FROM_MY_STORE_URL, request);
}

export function sendAddBundlesAutocomplete(request: AddFreeBundlesFromAutocomplete): WebSocketAction {
  return wsSendAction(ADD_BUNDLES_FROM_AUTOCOMPLETE_URL, request);
}

export function sendUpdateWasteRecycling(request: UpdateWasteRecycling): WebSocketAction {
  return wsSendActionNoTimeout(UPDATE_WASTE_RECYCLING_URL, request);
}

export function sendRemoveItem(request: RemoveItem): WebSocketAction {
  return wsSendAction(REMOVE_ITEM_URL, request);
}

export function sendClearItems(request: ClearItems): WebSocketAction {
  return wsSendAction(CLEAR_ITEMS_URL, request);
}

export function sendAddVehicle(request: AddVehicle): WebSocketAction {
  return wsSendAction(ADD_VEHICLE_URL, request);
}

export function sendUpdateVehicle(request: UpdateVehicle): WebSocketAction {
  return wsSendActionNoTimeout(UPDATE_VEHICLE_URL, request);
}

export function sendAddReferenceByReferenceNumber(request: AddItemByReferenceNumber): WebSocketAction {
  return wsSendAction(ADD_CATALOG_REFERENCE_BY_NUMBER_URL, request);
}

export function sendAddTireByReferenceNumber(request: AddItemByReferenceNumber): WebSocketAction {
  return wsSendAction(ADD_CATALOG_TIRE_BY_NUMBER_URL, request);
}

export function sendAddLaborTimeByCode(request: AddLaborTimeByCode): WebSocketAction {
  return wsSendAction(ADD_CATALOG_LABOR_TIME_BY_CODE_URL, request);
}

export function sendGetSettings(): WebSocketAction {
  return wsSendAction(GET_SETTINGS_URL, null);
}

export function sendAddCustomSetting(request: AddSettingsItem): WebSocketAction {
  return wsSendAction(ADD_SETTINGS_ITEM_URL, request);
}

export function sendRemoveCustomSetting(request: RemoveSettingsItem): WebSocketAction {
  return wsSendAction(REMOVE_SETTINGS_ITEM_URL, request);
}

export function sendUpdateWasteRecyclingSettings(request: UpdateWasteRecyclingSettings): WebSocketAction {
  return wsSendAction(UPDATE_SETTINGS_WASTE_RECYCLING_URL, request);
}

export function sendUpdateClientContact(request: UpdateClient): WebSocketAction {
  return wsSendActionNoTimeout(UPDATE_CLIENT_URL, request);
}

export function sendUpdateObservations(request: UpdateObservations): WebSocketAction {
  return wsSendAction(UPDATE_OBSERVATIONS_URL, request);
}

export function sendUpdateSettings(request: UpdateSettings): WebSocketAction {
  return wsSendActionNoTimeout(UPDATE_SETTINGS_URL, request);
}

export function sendAddCatalogReferencesToBasket(request: AddCatalogReferencesToBasket): WebSocketAction {
  return wsSendAction(ADD_CATALOG_REFERENCES_TO_BASKET_URL, request);
}

export function sendGetRepairOrderRequest(request: GetRepairOrderRequest): WebSocketAction {
  return wsSendAction(GET_REPAIR_ORDER, request);
}

export function sendEstimateToDMS(request: SendEstimateToDMSRequest): WebSocketAction {
  return wsSendAction(SEND_ESTIMATE_TO_DMS, request);
}

export function fillFromMaintenancePlan(request: AddFromMaintenancePlan): WebSocketAction {
  return wsSendAction(ESTIMATE_FROM_MAINTENANCE_PLAN, request);
}

export function sendDeleteEstimateRequest(request: DeleteEstimate): WebSocketAction {
  return wsSendAction(DELETE_ESTIMATE, request);
}

export function sendCancelDeletionRequest(request: DeleteEstimate): WebSocketAction {
  return wsSendAction(CANCEL_DELETION, request);
}

export function sendQueryAutocompleteBundlesRequest(request: AutocompleteSearchBundles): WebSocketAction {
  return wsSendAction(QUERY_BUNDLES_AUTOCOMPLETE_URL, request);
}

export function sendUpdateB2BCounterParties(request: UpdateB2BCounterParties): WebSocketAction {
  return wsSendActionNoTimeout(UPDATE_COUNTER_PARTIES_URL, request);
}

export function sendGetReferencesPriceRequest(
  estimateId: string,
  buyerTresorCode: string,
  sellerTresorCode: string,
  references: ReferenceTradingDataRequestDetail[],
): WebSocketAction {
  const request: GetReferenceTradingDataForB2BEstimateRequest = {
    references,
    estimateId,
    buyerTresorCode,
    sellerTresorCode,
  };
  return wsSendAction(GET_REFERENCES_PRICE_URL, request);
}

export function sendGetReferencesStocksRequest(
  estimateId: string,
  buyerTresorCode: string,
  sellerTresorCode: string,
  references: ReferenceStockTradingDataRequestDetail[],
  userContext: UserContext,
): WebSocketAction {
  const request: GetReferenceStocksForB2BEstimateRequest = {
    references,
    userContext,
    estimateId,
    buyerTresorCode,
    sellerTresorCode,
  };
  return wsSendAction(GET_REFERENCES_STOCKS_URL, request);
}

export function sendCheckoutB2BRequest(payload: CheckoutB2BEstimate): WebSocketAction {
  return wsSendAction(CHECKOUT_B2B_ESTIMATE_URL, payload);
}
