import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ROUTER_CATALOG_IAM, ROUTER_TIRES, ROUTER_UNIVERSAL_PRODUCTS } from 'app/AppRouter';
import { SContainer } from 'components/Page/Header/SubHeader/SubHeader.styled';
import { getLastVehicleDetail } from 'domains/catalog/Catalog.store';
import { theme } from 'styles';
import { BackButton, Box, CenterFlex, MarginBox } from 'UI';
import { CurrentVehicleButton } from 'UI/Button/CurrentVehicleButton';
import { HomeButton } from 'UI/Button/HomeButton';

export const SUBHEADER_HEIGHT = 48;

const SubHeader = () => {
  const location = useLocation();
  const currentVehicle = useSelector(getLastVehicleDetail);

  const getHeaderBackButton = () => {
    if (
      location.pathname.startsWith(ROUTER_TIRES) ||
      location.pathname.startsWith(ROUTER_UNIVERSAL_PRODUCTS) ||
      location.pathname.startsWith(ROUTER_CATALOG_IAM)
    ) {
      return currentVehicle ? <CurrentVehicleButton currentVehicle={currentVehicle} /> : <HomeButton />;
    }
    return <BackButton />;
  };

  return (
    <SContainer background={theme.color.white} minHeight={SUBHEADER_HEIGHT} maxHeight={SUBHEADER_HEIGHT}>
      <Box width={300}>
        <CenterFlex direction={'row'}>
          <MarginBox ml={30} />
          {getHeaderBackButton()}
        </CenterFlex>
      </Box>
      <Box width={300} />
    </SContainer>
  );
};

export default SubHeader;
