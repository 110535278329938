import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
// eslint-disable-next-line max-len
import { DelegateSessionConnectionBoxBody } from 'components/Page/Header/TopHeader/Profile/DelegateSessionConnectionBox/DelegateSessionConnectionBox';
import { getEstimateById, getEstimateClient, updateB2BCounterParties } from 'domains/estimate/Estimate.store';
import { Flex, LightCard, Spin, Text } from 'UI';
import { getData } from 'utils';

export interface ClientSectionProps {
  estimateId: string;
}

const ClientSectionB2B = ({ estimateId }: ClientSectionProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const estimate = getData(useSelector((state: RootState) => getEstimateById(state, estimateId)));
  const [selectedSeller, setSelectedSeller] = useState<string | undefined>();
  const [selectedBuyer, setSelectedBuyer] = useState<string | undefined>();
  const [expandSellersSelector, setExpandSellersSelector] = useState(false);
  const [expandBuyersSelector, setExpandBuyersSelector] = useState(false);
  const client = useSelector((state: RootState) => getEstimateClient(state, estimateId));
  const name = client?.name || '';
  const birCode = client?.tresorCode || '';
  const rrfCode = client?.rrfCode || '';
  const phoneNumber = client?.phoneNumber || '';
  const email = client?.email || '';

  const handleChangeBuyer = (buyer?: string) => {
    setSelectedBuyer(buyer);
    selectedSeller &&
      buyer &&
      dispatch(updateB2BCounterParties({ sellerTresorCode: selectedSeller, buyerTresorCode: buyer }));
  };

  useEffect(() => {
    estimate?.clientContact.tresorCode && setSelectedBuyer(estimate.clientContact.tresorCode);
    // eslint-disable-next-line
  }, [estimate?.clientContact.tresorCode]);

  useEffect(() => {
    client?.sellerTresorCode && setSelectedSeller(client.sellerTresorCode);
    // eslint-disable-next-line
  }, [client?.sellerTresorCode]);

  const isReloaded = !selectedBuyer || estimate?.clientContact.tresorCode === selectedBuyer;

  const setExpandSellers = (v: boolean) => {
    setExpandSellersSelector(v);
    setExpandBuyersSelector(false);
  };

  const setExpandBuyers = (v: boolean) => {
    setExpandBuyersSelector(v);
    setExpandSellersSelector(false);
  };

  return (
    <LightCard title={t('estimate.client_contact', 'Client contact')}>
      <Flex direction={'column'} gap={15} minHeight={200} maxWidth={670}>
        <DelegateSessionConnectionBoxBody
          selectedSeller={selectedSeller}
          setSelectedSeller={setSelectedSeller}
          selectedBuyer={selectedBuyer}
          setSelectedBuyer={handleChangeBuyer}
          expandSellersSelector={expandSellersSelector}
          setExpandSellersSelector={setExpandSellers}
          expandBuyersSelector={expandBuyersSelector}
          setExpandBuyersSelector={setExpandBuyers}
          clickable
          inline
          dropDownInputs
        />
        {isReloaded ? (
          <>
            <Text type={'text_dim'}>{name}</Text>
            {(birCode || rrfCode) && (
              <Text type={'text_dim'}>{`${t('common.bir_code', 'Bir code:')} ${birCode} - ${rrfCode}`}</Text>
            )}
            <Text type={'text_dim'}>{phoneNumber}</Text>
            <Text type={'text_dim'}>{email}</Text>
          </>
        ) : (
          <Spin></Spin>
        )}
      </Flex>
    </LightCard>
  );
};

export default ClientSectionB2B;
