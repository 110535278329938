import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { getEstimateB2BPrices, getEstimateById } from 'domains/estimate/Estimate.store';
import { ReferencePriceType } from 'domains/references';
import { getData, hasData, SearchData } from 'utils';

function hasAnyReferencePrice(references: string[], prices: Map<string, SearchData<ReferencePriceType>>) {
  return references.some((ref) => hasData(prices.get(ref)));
}

export const useIsValidB2BEstimateToOrder = (estimateId: string): { isValid: boolean; message: string } => {
  const { t } = useTranslation();

  const estimate = useSelector((state: RootState) => getEstimateById(state, estimateId));
  const estimateData = getData(estimate);
  const references =
    estimateData?.referenceSubsection.references
      .map((ref) => ref.referenceNumber)
      .concat(estimateData?.tireSubsection.references.map((ref) => ref.referenceNumber)) ?? [];
  const prices = useSelector((state: RootState) => getEstimateB2BPrices(state, estimateId));
  if (!estimateData?.clientContact.sellerTresorCode || !estimateData?.clientContact.tresorCode) {
    return {
      isValid: false,
      message: t('estimate.actions.order.validation.notSelectedSellerAndBuyer', 'Supplier or customer is not selected'),
    };
  }

  if (!references?.length) {
    return {
      isValid: false,
      message: t('estimate.actions.order.validation.noReferenceToOrder', 'No reference to order'),
    };
  }

  if (!hasAnyReferencePrice(references, prices)) {
    return {
      isValid: false,
      message: t('estimate.actions.order.validation.unableToLoadPrice', 'Unable to load price for given references'),
    };
  }

  return {
    isValid: true,
    message: '',
  };
};
