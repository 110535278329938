import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  OrderDeliveryType,
  OrderMKTPDeliveryMode,
} from '@1po/1po-bff-fe-spec/generated/order/request/CheckoutOrderRequest';
import PrivateComponent from 'composers/PrivateComponent';
import { SectionCard, SText } from 'pages/CartPage/ValidationStep/DeliveryAddressSection.styled';
import ModifyButton from 'pages/CartPage/ValidationStep/ModifyButton';
import { Box, Flex, PaddingBox, Radio, RadioGroup, Space, Text } from 'UI';
import { Country } from 'utils/i18n/Country';

interface DeliveryAddressSectionProps {
  validationDeliveryAddress: OrderDeliveryType;
  updateDeliveryAddress: (value: OrderDeliveryType) => void;
  orderMKTPDeliveryMode: OrderMKTPDeliveryMode | undefined;
  basketHasMKTPReference: boolean;
  updateOrderMKTPDeliveryMode: (value: OrderMKTPDeliveryMode) => void;
  sellerName?: string;
  compact?: boolean;
}

const DeliveryAddressSection = ({
  validationDeliveryAddress,
  updateDeliveryAddress,
  orderMKTPDeliveryMode,
  basketHasMKTPReference,
  updateOrderMKTPDeliveryMode,
  sellerName = '',
  compact = false,
}: DeliveryAddressSectionProps) => {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    setEditMode(false);
  }, [validationDeliveryAddress]);

  return (
    <Flex direction={'column'} dataCy={'section-delivery-options'} gap={compact ? 10 : 30}>
      <SectionCard direction={'row'} align={'center'} editMode={editMode}>
        <Flex direction={'column'} alignSelf={'flex-start'}>
          <Text type={'h4_paragraph'}>{t('order.validation.delivery.title', '1. Delivery address')}</Text>
          <Box height={10} />
          {!editMode && (
            <>
              <Text type={'light_14_black_85'}>
                {validationDeliveryAddress === 'OWN_ADDRESS'
                  ? t('order.validation.address.options.my_address', 'Deliver to my address')
                  : `${t('order.validation.address.options.dealer_address', 'Dealer pick-up at:')} ${sellerName}`}
              </Text>
              {basketHasMKTPReference && (
                <Text type={'light_14_black_85'}>
                  {orderMKTPDeliveryMode === 'STANDARD'
                    ? t('order.validation.delivery.options.standard', 'Standard delivery - 10,00€')
                    : t('order.validation.delivery.options.multiple', 'Multiple deliveries - 10,00€ per delivery')}
                </Text>
              )}
            </>
          )}
          <Text type={'light_14_black_65'}>
            {t(
              'order.validation.address.description',
              'The selected delivery options will apply by default to your entire order.',
            )}
          </Text>
          {editMode && (
            <>
              {!compact && <Box height={30} />}
              <RadioGroup value={validationDeliveryAddress}>
                <Space direction={'vertical'} size={15}>
                  <Radio
                    value={'OWN_ADDRESS'}
                    onChange={() => updateDeliveryAddress('OWN_ADDRESS')}
                    label={
                      <Text type={'light_14_black_65'} cursor={'pointer'}>
                        {t('order.validation.address.options.my_address', 'Deliver to my address')}
                      </Text>
                    }
                  />
                  <Radio
                    value={'DEALER_PICKUP'}
                    onChange={() => updateDeliveryAddress('DEALER_PICKUP')}
                    label={
                      <Text type={'light_14_black_65'} cursor={'pointer'}>
                        {`${t('order.validation.address.options.dealer_address', 'Dealer pick-up at:')} ${sellerName}`}
                      </Text>
                    }
                  />
                </Space>
              </RadioGroup>
              {basketHasMKTPReference && (
                <PrivateComponent
                  render={() => (
                    <PaddingBox px={32} py={32}>
                      <MKTPDelivery
                        orderMKTPDeliveryMode={orderMKTPDeliveryMode}
                        updateOrderMKTPDeliveryMode={updateOrderMKTPDeliveryMode}
                      />
                    </PaddingBox>
                  )}
                  requiredCountries={[Country.IT]}
                />
              )}
            </>
          )}
        </Flex>
        <ModifyButton editMode={editMode} handleEdit={setEditMode} />
      </SectionCard>
    </Flex>
  );
};

interface MKTPDeliveryProps {
  orderMKTPDeliveryMode: OrderMKTPDeliveryMode | undefined;
  updateOrderMKTPDeliveryMode: (value: OrderMKTPDeliveryMode) => void;
}

function MKTPDelivery(props: MKTPDeliveryProps) {
  const { t } = useTranslation();

  useEffect(() => {
    if (props.orderMKTPDeliveryMode === undefined) {
      props.updateOrderMKTPDeliveryMode('STANDARD');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Text type={'h4_paragraph'}>
        {t('order.validation.delivery.description', 'Shipping cost for other brand parts')}
      </Text>
      <Box height={15} />
      <RadioGroup
        onChange={(event) => props.updateOrderMKTPDeliveryMode(event.target.value)}
        value={props.orderMKTPDeliveryMode}
      >
        <Flex direction={'column'}>
          <Radio
            value={'STANDARD'}
            label={
              <Text type={'h6'} cursor={'pointer'}>
                {t('order.validation.delivery.options.standard', 'Standard delivery - 10,00€')}
              </Text>
            }
          />
          <SText type={'light_14_black_65'}>
            {t('order.validation.delivery.options.standard.description', 'Receive all your items at once')}
          </SText>
          <Box height={30} />
          <Radio
            value={'MULTIPLE'}
            label={
              <Text type={'h6'} cursor={'pointer'}>
                {t('order.validation.delivery.options.multiple', 'Multiple deliveries - 10,00€ per delivery')}
              </Text>
            }
          />
          <SText type={'light_14_black_65'}>
            {t(
              'order.validation.delivery.options.multiple.description',
              'Receive your items as soon they are available',
            )}
          </SText>
        </Flex>
      </RadioGroup>
    </>
  );
}

export default DeliveryAddressSection;
