/**
 * Destinations
 */
export const WS_DESTINATION_PREFIX = '/app';
export const DESTINATION_QUEUE_MAIN = 'queue/main';
export const DESTINATION_QUEUE_ERROR = 'queue/error';
/**
 * URL paths
 */
export const SEARCH_BY_VIN_OR_VRN_URL = '/vehicles/search/vin-or-vrn';
export const SEARCH_BY_BMM_URL = '/vehicles/search/bmm';
export const GET_VEHICLE_BRANDS_URL = '/vehicles/brands';
export const GET_IAM_VEHICLE_MODELS_URL = '/vehicles/iam/models';
export const GET_IAM_VEHICLE_VERSIONS_URL = '/vehicles/iam/versions';
export const GET_VEHICLE_ENGINES_URL = '/vehicles/engines';
export const GET_VEHICLE_SEARCH_HISTORY_URL = '/vehicles/search-history/get';
export const ADD_VEHICLE_TO_SEARCH_HISTORY_URL = '/vehicles/search-history/add-vehicle';
export const REMOVE_VEHICLE_FROM_SEARCH_HISTORY_URL = '/vehicles/search-history/remove-vehicle';
export const CLEAR_SEARCH_HISTORY_URL = '/vehicles/search-history/clear';
export const GET_VEHICLE_TECHNICAL_CRITERIA_URL = '/vehicles/technical-criteria/get';
export const GET_IAM_VEHICLE_TECHNICAL_CRITERIA_URL = '/vehicles/iam/technical-criteria/get';
export const SELECT_VEHICLE_URL = '/vehicles/select';
export const GET_VEHICLE_CATEGORIES_URL = '/vehicles/categories/get';
export const GET_VEHICLE_CATEGORY_IMAGES_URL = '/vehicles/categories/get-images';
export const GET_LEVEL3_URL = '/catalog/tree/level3-details';
export const GET_REFERENCE_DETAILS_URL = '/catalog/references/get-reference-details';
export const GET_PLATE_REFERENCES_URL = '/catalog/references/search-plate-references';
export const GET_REFERENCES_PRICE_URL = '/catalog/references/prices/get';
export const GET_REFERENCES_STOCKS_URL = '/catalog/references/stocks/get';
export const GET_REFERENCES_DISCOUNTS_URL = '/catalog/references/discounts/get';
export const GET_REFERENCES_REPAIR_PRICES_URL = '/catalog/references/repairPrices/get';
export const CHECK_IF_STOCKS_ARE_UP_TO_DATE_URL = '/catalog/references/stocks/check-if-stocks-are-up-to-date';
export const GET_PLATES_FOR_REFERENCE = '/catalog/references/get-plates-for-reference';
export const GET_MARKETING_REFERENCES_URL = '/catalog/references/search-marketing-references';
export const GET_IAM_REFERENCES_URL = '/catalog/iam/references/get-references';
export const GET_REUSE_STOCK_URL = '/catalog/references/get-reuse-stock';
export const GET_VEHICLE_OTS_URL = '/vehicles/ots';
export const GET_UNIVERSAL_PRODUCTS_TREE_URL = '/catalog/universal_products/tree';
export const GET_UNIVERSAL_PRODUCTS_URL = '/catalog/universal_products/products';
export const GET_LABOR_TIMES_URL = '/catalog/labor-time/get-labor-times';
export const GET_IAM_LABOR_TIMES_URL = '/catalog/labor-time/get-iam-labor-times';
export const GET_LABOR_TIME_IDS_BY_GENERIC_PART_URL = '/catalog/labor-time/get-ids-by-generic-part';
export const GET_IAM_TECHNICAL_DATA_TREE_URL = '/catalog/technical-data/get-iam-tree';
export const GET_IAM_TECHNICAL_DATA_DETAILS_URL = '/catalog/technical-data/get-iam-details';
export const GET_IAM_AVAILABLE_MODULES_TREE_URL = '/catalog/available-data/get-modules';
export const GET_IAM_SERVICE_OPERATIONS_TREE_URL = '/catalog/service-operations/get-iam-tree';
export const GET_IAM_SERVICE_PROPOSAL_URL = '/catalog/service-operations/get-proposal';
export const GET_IAM_SERVICE_PROPOSAL_OPERATIONS_URL = '/catalog/service-operations/get-proposal-operations';
export const GET_IAM_SERVICE_CHECKLIST_URL = '/catalog/service-operations/get-checklist';
export const GET_IAM_REPAIR_METHODS_URL = '/catalog/repair-methods/get-iam-list';
export const GET_IAM_REPAIR_METHODS_TREE_URL = '/catalog/repair-methods/get-iam-tree';
export const GET_IAM_REPAIR_METHOD_DETAIL_URL = '/catalog/repair-methods/get-iam-detail';
export const GET_MAINTENANCE_PLAN_URL = '/catalog/get-maintenance-plan';
export const GET_MAINTENANCE_VALUES_URL = '/catalog/get-maintenance-values';
export const GET_MAINTENANCE_MILEAGE_URL = '/catalog/get-maintenance-car-mileage';
export const GET_MAINTENANCE_PLANNED_OPERATIONS_URL = '/catalog/get-maintenance-planned-operations';
export const GET_FULL_TEXT_AUTOCOMPLETE_URL = '/catalog/text-search/get-full-text-autocomplete';
export const GET_TEXT_SEARCH_URL = '/catalog/text-search/get-text-search';
export const CHECKOUT_ORDER_URL = '/order/checkout';
export const ORDER_LIST_GET_URL = '/order/list';
export const ORDER_LIST_DOWNLOAD_URL = '/order/list/download';
export const ORDER_IS_RETURNS_FEATURE_ENABLED_URL = '/order/is-returns-feature-enabled';
export const SEND_CLAIM_URL = '/order/send-claim';
export const ORDER_GET_BY_ID_URL = '/order/get';
export const ORDER_RE_ADD_REFERENCES_TO_BASKET = '/order/re-add-to-user-basket';
export const EXTERNAL_ORDER_LIST_GET_URL = '/external-order/list';
export const GET_BUILD_VERSIONS_URL = '/build-versions/get';
export const GET_APPLICATION_CONFIG = '/app-config/get';
export const GET_AUTH_CONFIG = '/auth/config';
export const MODIFY_MOCK_CONFIG = '/app-config/modify-mock-config';
export const PUT_CLIENT_LOG = '/client-log/put';
export const GET_SERVER_TIME = "/time/get";
export const GET_URL_SIGNATURE = '/url-signature/get';
export const SEND_EMAIL_FROM_GARAGE = '/email/send-from-garage';
export const SEND_EMAIL_TO_ASSISTANCE = '/email/send-to-assistance';
export const SEND_EMAIL_DOCUMENTATION_ALERT_MOTRIO = '/email/send-documentation-alert-motrio';
export const TIRE_FULLTEXT_SEARCH = '/tire/fulltext-search';
export const TIRE_UNIQUE_DIMENSION_SEARCH = '/tire/unique-dimension-search';
export const TIRE_PRODUCTS_SEARCH = '/tire/product-search';
export const TIRE_REPLACEMENT_PRODUCTS_SEARCH = '/tire/replacement-product-search';
export const GET_MPR_VALIDATION = '/tire/mpr-validation-search';
export const TIRE_DATAHUB_VIN_SEARCH = '/tire/datahub/vin-search';
export const GET_TIRE_BRAND_CATEGORIES = '/tire/get-tire-brand-categories';
export const GET_AVAILABLE_DMS_SERVICES = '/dms/get-available-services';
export const GET_REPAIR_ORDER = '/dms/get-repair-order';
export const SEND_ESTIMATE_TO_DMS = '/dms/export-estimate';
export const GET_IAM_TIRES = '/tire/get-iam-tires';
export const GET_BASE64_FROM_PICTURE_URL = '/picture/get-base64-from-url';
export const SEND_RETURN_REQUEST_EMAIL = '/email/return-request';
/* MY STORE - BUNDLES - START */
export const MY_STORE_BUNDLES_SUBSCRIBE = '/myStore/bundles/subscribe';
export const MY_STORE_BUNDLES_CREATE_NEW_SECTION = '/myStore/bundles/section/create';
export const MY_STORE_BUNDLES_REMOVE_SECTION = '/myStore/bundles/section/remove';
export const MY_STORE_BUNDLES_RENAME_SECTION = '/myStore/bundles/section/rename';
export const MY_STORE_BUNDLES_MOVE_SECTION = '/myStore/bundles/section/move';
export const MY_STORE_BUNDLES_CREATE_NEW_BUNDLE = '/myStore/bundles/bundle/create';
export const MY_STORE_BUNDLES_REMOVE_BUNDLE = '/myStore/bundles/bundle/remove';
export const MY_STORE_BUNDLES_UPDATE_BUNDLE_CODE = '/myStore/bundles/bundle/updateCode';
export const MY_STORE_BUNDLES_UPDATE_BUNDLE_PRICE = '/myStore/bundles/bundle/updatePrice';
export const MY_STORE_BUNDLES_UPDATE_BUNDLE_DESIGNATION = '/myStore/bundles/bundle/updateDesignation';
/* MY STORE - BUNDLES - END */
/* MY STORE - TIRES - START */
export const MY_STORE_TIRES_SUBSCRIBE = '/myStore/tires/subscribe';
export const MY_STORE_TIRES_UPDATE_DISCOUNT = '/myStore/tires/update/discount';
/* MY STORE - TIRES - END */
export const GET_SSO_LINK = '/sso/get-link';
